import React, { Fragment } from "react";

import BaseNavbar from "components/Navbars/BaseNavbar";
import Footer from "components/Footers/DefaultFooter";

import {
  Row,
  Col,
  Card,
  CardBody,
} from 'reactstrap';

import { useSelector } from 'react-redux';
import { FINANCE_DEPARTMENT, LAW_DEPARTMENT, ORGANIZATIONAL_DEPARTMENT, GENERAL_DEPARTMENT} from 'helpers/utils';
import { strToUpper } from 'helpers/utils';


const Admin = (props) => {

  const groups = useSelector(state => state.company.groups);

  const lg = Math.floor(12 / groups.length);
  const md = Math.floor(lg * 2);

  const {history} = props;

  const companyName = useSelector(state => state.company.companyName);

  document.title = (companyName) ? strToUpper(companyName)  : "ERP";

  const cards = groups.map((group, index) => {
    let className, name, href;
    switch (group.name) {
      case FINANCE_DEPARTMENT:
        className = 'bg-bank';
        name = 'Maliyyə şöbəsi';
        href = '/finance';
        break;
      case LAW_DEPARTMENT:
        className = 'bg-notification';
        name = 'Hüquq şöbəsi';
        href = '/law';
        break;
      case ORGANIZATIONAL_DEPARTMENT:
        className = 'bg-payment';
        name = 'Təşkilat şöbəsi';
        href = '/organizational';
        break;
      case GENERAL_DEPARTMENT:
        className = 'bg-default';
        name = 'Ümumi şöbə';
        href = '/general';
        break;
      default:
        break;
    }

    if (className && name && href) {
      className = "department-div shadow d-flex justify-content-center " + className;
      return (
        <Col md={md} lg={lg} key={index} onClick={() => history.push(href) }>
          <Card className={className}>
            <CardBody className="d-flex justify-content-center align-items-center">
              <h1 className="w-100 text-center text-white">{name}</h1>
            </CardBody>
          </Card>
        </Col>
      )
    }
    return null;
  });

  return (
    <Fragment>
      <BaseNavbar />
      <div className="container">
        <Row className="department-row">
          {cards}
        </Row>
      </div>
      <Footer className="admin-footer" />
    </Fragment>
  );
}



export default Admin;
