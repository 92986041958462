import React, { useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { changeInputData, changeNotificationStatus } from '../../store/actions/payerDetail';
import { setInitial } from 'store/actions/index';
import { useLocation, Link } from "react-router-dom";
import { store } from 'react-notifications-component';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  Card,
  CardHeader,
  Container,
  Button,
  Input,
  Row,
  Col,
  CardBody,
  NavLink,
  Label,
  FormGroup,
  Form,
  // FormFeedback
} from "reactstrap";

import Address from 'components/Payer/Address';

import PhoneInput from 'react-phone-number-input/input';
import { getPrivilegesType, getServices } from 'store/actions/company';
import DebtForm from "components/Payer/Debt";


import addPayer, { updateDebts, readyForFetch, btnDisabledEvent } from "store/actions/addPayer";
// import { useForm } from "react-hook-form";

const AddPayer = props => {

  const location = useLocation();
  const pageHeader = (location.pathname.includes('non-population')) ? "Abonentlər Kommersiya" : "Abonentlər Əhali";

  const {
    onSetInitial, onChangeInputData, notificationStatus, changeNotfStatus, onGetPrivilegesType, privilegesType,
    memberId, address, residential_area, privilege, fullName, phone, services, onGetServices, debts, onUpdateDebts, onReadyForFetch,
    savePayer, onAddPayer, land_area, btnDisabled, onBtnDisabledEvent, region, house
  } = props;


  useEffect(() => {
    onSetInitial();
  }, [onSetInitial]);

  useEffect(() => {
    if (!services) {
      onGetServices();
    }
  }, [services, onGetServices]);

  useEffect(() => {
    if (!privilegesType) {
      onGetPrivilegesType();
    }
  }, [onGetPrivilegesType, privilegesType]);



  const getData = useCallback(() => {
    const payer = {
      full_name: fullName,
      member_id: memberId,
      address: address,
      residential_area: residential_area,
      land_area: land_area,
      phone: phone,
      privilege: privilege,
      region: region,
      house: house,
    };


    return {
      payer: payer,
      debt: debts,
    };
  }, [privilege, fullName, memberId, address, residential_area, phone, debts, land_area, region, house]);


  useEffect(() => {
    if (savePayer) {
      onBtnDisabledEvent(true);
      onAddPayer(getData());
      onReadyForFetch(false);
    }
  }, [savePayer, onReadyForFetch, onAddPayer, getData, onBtnDisabledEvent]);



  const getServicesData = useCallback(() => {
    const type = (location.pathname.includes('non-population')) ? 'non-population' : 'population';

    if (services) {
      return services.filter(service => service.type === type);
    }
    return null;
  }, [services, location.pathname]);


  const getOptions = useCallback(() => {
    if (!privilegesType) return null;

    return privilegesType.map((type, i) => {
      if (privilege && privilege === type.id) {
        return <option value={type.id} selected key={i}>{type.name}</option>
      }

      return <option value={type.id} key={i}>{type.name}</option>
    });

  }, [privilegesType, privilege]);


  useEffect(() => {
    if (notificationStatus) {
      if (notificationStatus === 'success') {
        // NotificationManager.success('Dəyişiklər müvəffəqiyyətlə yadda saxlanıldı.', 'Uğurlu əməliyyat!');
        store.addNotification({
          title: "Uğurlu əməliyyat!",
          message: 'Dəyişiklər müvəffəqiyyətlə yadda saxlanıldı.',
          type: 'success',
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        });
        changeNotfStatus();
        onSetInitial();
      }
      else if (notificationStatus === 'error') {
        // NotificationManager.error('Xəta baş verdi.', 'Uğursuz əməliyyat');
        store.addNotification({
          title: "Uğursuz əməliyyat!",
          message: 'Xəta baş verdi.',
          type: 'danger',
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        });
        changeNotfStatus();
      }
    }
  }, [notificationStatus, changeNotfStatus, onSetInitial]);

  const updateDebt = useCallback((id, debt) => {
    const updatedDebts = [...debts];
    updatedDebts[id].service = debt.service;
    updatedDebts[id].main_amount = debt.main_amount;
    updatedDebts[id].final_amount = debt.final_amount;
    onUpdateDebts(updatedDebts);
  }, [debts, onUpdateDebts]);


  const deleteDebt = useCallback(id => {
    const updatedDebts = [...debts];
    updatedDebts.splice(id, 1);
    onUpdateDebts(updatedDebts);
  }, [debts, onUpdateDebts]);


  const getDebtsForm = useCallback(() => {
    if (!services) return null;
    if (privilege) {
      if (debts && debts.length) onUpdateDebts([]);
      return null
    }
    return debts.map((debt, i) => {
      return <DebtForm
        key={i}
        debtID={i}
        services={getServicesData()}
        {...debt}
        onChange={(id, debt) => updateDebt(id, debt)}
        onDelete={() => deleteDebt(i)}
      />
    });
  }, [services, debts, getServicesData, updateDebt, deleteDebt, privilege, onUpdateDebts]);


  const newDebt = useCallback(() => {
    const updatedDebts = [...debts];
    updatedDebts.push({
      service: null,
      main_amount: 0,
      final_amount: 0
    });
    onUpdateDebts(updatedDebts);
  }, [onUpdateDebts, debts]);

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault();
    onReadyForFetch(true);
  }, [onReadyForFetch]);


  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <Link color="inherit" to={location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1)} className="p-0 nav-link">
          {pageHeader}
        </Link>
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          Yeni Abonent
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <h2 className="mb-0 d-flex">Yeni Abonent</h2>
        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <Form onSubmit={handleOnSubmit}>
            <Row>
              <Col md="4">
                <FormGroup>
                  <Label>Ad, Soyad, Ata adı *</Label>
                  <Input
                    placeholder="Ad, Soyad, Ata adı"
                    type="text"
                    className="form-control form-control-sm"
                    onChange={(e) => onChangeInputData('full_name', e.target.value)}
                    value={(fullName) ? fullName : ''}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>YVÖK</Label>
                  <Input
                    placeholder="YVÖK"
                    type="text"
                    className="form-control form-control-sm"
                    onChange={(e) => onChangeInputData('member_id', e.target.value)}
                    value={(memberId) ? memberId : ''}
                  />
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Ünvan *</Label>
                  <Input
                    placeholder="Ünvan"
                    type="textarea" row={2}
                    className="form-control form-control-sm"
                    value={(address) ? address : ''}
                    onChange={(e) => onChangeInputData('address', e.target.value)}
                    required
                  />
                  {/* {errors.addressRequired && <FormFeedback>Ünvan daxil edin!</FormFeedback>} */}
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>İmtiyaz</Label>
                  <Input type="select" name="select"
                    onChange={(e) => onChangeInputData('privilege', e.target.value)}
                    className="form-control form-control-sm" >
                    <option value="" defaultValue>-------</option>
                    {getOptions()}
                  </Input>
                  <small>* İmtiyazı olan abonent üçün borc əlavə edilmir.</small>
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>Sahəsi (m<sup>2</sup>)</Label>
                  <Input
                    placeholder="Sahəsi"
                    type="number"
                    className="form-control form-control-sm"
                    value={(residential_area) ? residential_area : ''}
                    onChange={(e) => onChangeInputData('residential_area', e.target.value !== '' ? e.target.value : null)}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>Torpaq sahəsi (ha)</Label>
                  <Input
                    placeholder="Torpaq sahəsi"
                    type="number"
                    className="form-control form-control-sm"
                    value={(land_area) ? land_area : ''}
                    onChange={(e) => onChangeInputData('land_area', e.target.value !== '' ? e.target.value : null)}
                  />
                </FormGroup>
              </Col>

              <Col md="4">
                <FormGroup>
                  <Label>Əlaqə nömrəsi</Label>
                  <PhoneInput
                    placeholder="Əlaqə nömrəsi"
                    country="AZ"
                    defaultCountry="AZ"
                    className="form-control form-control-sm"
                    international
                    withCountryCallingCode
                    value={(phone) ? phone : ''}
                    onChange={(p) => onChangeInputData('phone', p)}
                  />
                </FormGroup>
              </Col>


              <Address region={region} house={house} onChangeInputData={onChangeInputData} method='create' />

            </Row>

            <hr className="my-4" />

            <h6 className="heading-small text-muted mb-4">
              Borc məlumatları
            </h6>

            <Row>
              {getDebtsForm()}
            </Row>
            <Button className="btn"
              color="info"
              onClick={newDebt}
              disabled={btnDisabled || privilege}
            >
              <i className="fa fa-plus mr-2"></i> Borc məlumatı əlavə et
            </Button>
            <Button className="btn custom-btn-style"
              color="info"
              type="submit"
              disabled={btnDisabled}
            >
              Yadda saxla
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}



const mapStateToProps = state => {
  return {
    memberId: state.addPayer.member_id,
    fullName: state.addPayer.full_name,
    address: state.addPayer.address,
    privilege: state.addPayer.privilege,
    phone: state.addPayer.phone,
    residential_area: state.addPayer.residential_area,
    notificationStatus: state.addPayer.notificationStatus,
    privilegesType: state.company.privilegesType,
    services: state.company.services,
    debts: state.addPayer.debts,
    savePayer: state.addPayer.savePayer,
    land_area: state.addPayer.land_area,
    btnDisabled: state.addPayer.btnDisabled,
    region: state.addPayer.region,
    house: state.addPayer.house
  };
};




const mapDispatchToProps = dispatch => {
  return {
    onChangeInputData: (column = null, value = null) => dispatch(changeInputData(column, value)),
    onSetInitial: () => dispatch(setInitial()),
    changeNotfStatus: () => dispatch(changeNotificationStatus()),
    onGetPrivilegesType: () => dispatch(getPrivilegesType()),
    onGetServices: () => dispatch(getServices()),
    onAddPayer: (data) => dispatch(addPayer(data)),
    onUpdateDebts: (debts) => dispatch(updateDebts(debts)),
    onReadyForFetch: (s) => dispatch(readyForFetch(s)),
    onBtnDisabledEvent: (s = false) => dispatch(btnDisabledEvent(s)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(AddPayer);
