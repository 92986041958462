import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader, InfoWindow } from "@react-google-maps/api";
import { connect } from 'react-redux';

import mapData, { updateMapData } from 'store/actions/map';
import { Link } from "react-router-dom";


const containerStyle = {
  width: '100%',
  height: '400px'
};


const NewGoogleMap = (props) => {

  const { onMapData, points, onUpdatePoints } = props;

  const [map, setMap] = useState(undefined);

  const [infos, setInfos] = useState([]);

  useEffect(() => {
    onMapData();
  }, [onMapData]);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBEV9D_hIRurrr4UbY4G3KRg4e7e2ND2kw"
  });


  useEffect(() => {
    if (points && isLoaded && !loadError && map) {
      const google = window.google;
      const bounds = new google.maps.LatLngBounds();
      points.forEach((p) => bounds.extend(p.coordinate));
      map.state.map.fitBounds(bounds);
    }

    if (window.hasOwnProperty('socket')) {

      window.socket.removeAllListeners('inspectors');

      window.socket.on('inspectors', payload => {

        const updatedPoints = [];
        if (points) {
          points.forEach((p, i) => {
            if (p.id === payload.data.id) {
              updatedPoints[i] = payload.data;
            } else {
              updatedPoints[i] = p;
            }
          });
        } else {
          updatedPoints.push(payload.data);
        }

        onUpdatePoints(updatedPoints);

      });
    }

  }, [points, isLoaded, loadError, map, onUpdatePoints]);



  const markerOnclick = useCallback((i) => {
    const infosUpdate = [
      ...infos
    ];
    infosUpdate[i] = !infos[i];
    setInfos(infosUpdate);
  }, [setInfos, infos]);

  const getMarkers = useCallback(() => {
    if (!points || !isLoaded || loadError) {
      return null;
    }

    const google = window.google;

    return points.map((p, i) => {

      let icon = {
        url: (p.status) ? require("assets/img/icons/common/inspector_online.png").default : require("assets/img/icons/common/inspector.png").default,
        scaledSize: new google.maps.Size(35, 35)
      }

      return <Marker
        key={i}
        position={p.coordinate}
        icon={icon}
        clickable={true}
        onClick={e => markerOnclick(i)}
      >
        {(infos && infos[i]) ?
          <InfoWindow
            onCloseClick={e => markerOnclick(i)}
          >
            <Link to={'/finance/inspectors/' + p.type + '/' + p.id}>{p.full_name}</Link>
          </InfoWindow> : null
        }
      </Marker>;
    });

  }, [points, isLoaded, loadError, infos, markerOnclick]);


  return (isLoaded && !loadError) ? <GoogleMap
    //options={options}
    mapContainerStyle={containerStyle}
    zoom={11}
    ref={map => setMap(map)}
    // {...props}
    center={props.center}
  >
    {getMarkers()}
  </GoogleMap> : null;
}


const mapStateToProps = state => {
  return {
    points: state.map.points,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    onMapData: () => dispatch(mapData()),
    onUpdatePoints: (points) => dispatch(updateMapData(points)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(NewGoogleMap));
