
const chars = [
  ['ə', 'Ə'],
  ['ğ', 'Ğ'],
  ['ü', 'Ü'],
  ['ş', 'Ş'],
  ['i', 'İ'],
  ['ç', 'Ç'],
];


export const strToUpper = (value) => {
  if (!value) {
    return null;
  }
  for (let i = 0; i < chars.length; i++) {
    value = value.replaceAll(chars[i][0], chars[i][1]);
  }

  return value.toUpperCase();
}


export const shortName = (value) => {
  let name = '';
  if (value) {
    const fn = value.split(" ");
    for (let i = 0; i < fn.length; i++) {
      if (i < 2) {
        name += fn[i].toUpperCase().charAt(0);
      }
    }
  }
  return name;
}


export const notificationOptions = {
  title: "Uğurlu əməliyyat!",
  message: 'Dəyişikliklər müvəffəqiyyətlə yadda saxlanıldı.',
  type: 'success',
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 2000,
    onScreen: true
  }
}


export const FINANCE_DEPARTMENT = "Finance Department";
export const LAW_DEPARTMENT = "Law Department";
export const GENERAL_DEPARTMENT = "General Department";
export const ORGANIZATIONAL_DEPARTMENT = "Organizational Department";



export const filterGroups = (group) => {
  switch (group.name) {
    case FINANCE_DEPARTMENT:
      return true;
    case LAW_DEPARTMENT:
      return true;
    case GENERAL_DEPARTMENT:
      return true;
    case ORGANIZATIONAL_DEPARTMENT:
      return true;
    default:
      return false;
  }
}



export const fileNames = (files) => {
  const names = [];
  for (let i = 0; i < files.length; i++) {
    names.push(files[i].name);
  }
  return names.join(', ');
}

export const getDepartmentName = (department) => {
  switch (department) {
    case FINANCE_DEPARTMENT:
    case 'finance':
      return "Maliyyə şöbəsi";
    case LAW_DEPARTMENT:
    case 'law':
      return "Hüquq şöbəsi";
    case GENERAL_DEPARTMENT:
    case 'general':
      return "Ümumi şöbə";
    case ORGANIZATIONAL_DEPARTMENT:
    case 'organizational':
      return "Təşkilat şöbəsi";
    default:
      return null;
  }
}

