import { FETCH_DOCUMENT_SUCCESS, FETCH_DOCUMENT_FAIL } from "./actionTypes";
import { DOCUMENT_TAGS, DOCUMENT_TYPES } from 'api/urls';



const documentData = (url) => {
  return dispatch => {
    fetch(url, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_DOCUMENT_SUCCESS,
          data: { ...data },
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_DOCUMENT_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}


export const saveDocument = async ({ data, url }) => {
  const formData = new FormData();

  for (const key in data) {
    if (key === 'document' || key === 'tags' || key === 'types') {
      for (let i = 0; i < data[key].length; i++) {
        formData.append(key, data[key][i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }

  return await fetch(url, {
    method: "POST",
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token'),
    },
    body: formData
  });
}

export const saveEditedDocument = async ({ data, url }) => {
  const formData = new FormData();

  for (const key in data) {
    if (key === 'document' || key === 'tags' || key === 'types') {
      for (let i = 0; i < data[key].length; i++) {
        formData.append(key, data[key][i]);
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return await fetch(url, {
    method: "POST",
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token'),
    },
    body: formData
  });
}

export const changeDocumentStatus = async (status, url) => {
  return await fetch(url, {
    method: "PUT",
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status }),
  });
}

export const documentDetail = async (url) => {
  return await fetch(url, {
    method: "GET",
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }
  });
}

export const typesAndTags = async () => {

  let options = {
    method: "GET",
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }
  }

  let [types, tags] = await Promise.all([
    fetch(DOCUMENT_TYPES, { ...options }),
    fetch(DOCUMENT_TAGS, { ...options }),
  ]);

  if (!types.ok || !tags.ok) {
    throw new Error("error occured");
  }

  types = await types.json();
  tags = await tags.json();

  return {
    types,
    tags
  }

}


export const setTypesAndTags = () => {

  return dispatch => {
    let options = {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    }

    Promise.all([
      fetch(DOCUMENT_TYPES, { ...options }),
      fetch(DOCUMENT_TAGS, { ...options }),
    ]).then(([types, tags]) => {

      if (!types.ok || !tags.ok) {
        throw new Error("error occured");
      }
      console.log(types);
      console.log(tags);
      return Promise.all([types.json(), tags.json()]);
    }).then(([types, tags]) => {
      dispatch({
        type: FETCH_DOCUMENT_SUCCESS,
        data: {
          types: types ?? [],
          tags: tags ?? [],
          fetched: true,
        },
      });
    })
      .catch(err => {
        dispatch({
          type: FETCH_DOCUMENT_FAIL,
          data: {
            error: err,
          }
        });
      });
  }

}

export default documentData;
