import React, { useEffect, useCallback } from "react";
import { connect } from 'react-redux';

import { navbarSearch, inputSearchVal } from '../../store/actions/navbarToggle';

import {
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Input,
  InputGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  Dropdown,
  Badge
} from "reactstrap";



const SearchBar = (props) => {


  const { searchResult, dropdownShow, onNavbarSearch, searchValue, setInputVal } = props;


  useEffect(() => {

    if (searchValue) {
      const interval = setTimeout(() => {
        onNavbarSearch(searchValue);
      }, 1000);

      return () => clearInterval(interval);
    }

  }, [onNavbarSearch, searchValue])


  const inputSearch = useCallback((value) => {
    setInputVal(value);
  }, [setInputVal])

  const getItems = useCallback((results) => {
    if (results) {
      return results.map((row, key) => {
        let type = row.type + 's';
        let href = (row.base_type) ? '/finance/' + type + '/' + row.base_type + '/' + row.id : '#';
        return (
          <DropdownItem href={href} key={key} className="w-100 d-flex">
            <div className="w-75 mr-2 text-truncate">
              <span>{row.full_name}</span>
            </div>
            <div className="w-25">
              {(row.type === 'inspector') ? <Badge color="info" className="w-20"> Müfəttiş </Badge> : <Badge color="warning" className="w-20">Abonent</Badge>}
            </div>
          </DropdownItem>
        );
      });
    }
    return null;
  }, []);

  return (
    <Form className="navbar-search navbar-search-light form-inline mr-3 d-none d-md-flex ml-lg-auto">
      <FormGroup className="mb-0">
        <Dropdown>
          <InputGroup className="input-group-alternative custom-navbar-search">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Axtar" type="text" className="form-control-sm" onChange={(e) => inputSearch(e.target.value)} />
          </InputGroup>
          {(searchResult) ?
            <DropdownMenu className={(dropdownShow) ? "show custom-dropdown-menu" : "dropdown-menu-arrow custom-dropdown-menu"} right>
              {getItems(searchResult)}
            </DropdownMenu> : null}
        </Dropdown>
      </FormGroup>
    </Form>
  );
}



const mapStateToProps = state => {
  return {
    searchResult: state.navbarToggle.searchResult,
    dropdownShow: state.navbarToggle.dropdownShow,
    searchValue: state.navbarToggle.searchValue,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onNavbarSearch: (value) => dispatch(navbarSearch(value)),
    setInputVal: (value) => dispatch(inputSearchVal(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
