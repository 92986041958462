import React, { useCallback } from "react";

import {
  Row,
  Col,
  FormGroup,
  Input,
  Label
} from 'reactstrap';
import { useRegion } from "hooks/address";
import { useHouse } from "hooks/address";


const Address = React.memo(props => {

  const { method, onChangeInputData, region, house, disabled } = props;

  const regionList = useRegion();

  const houseList = useHouse(region);


  const getOptions = useCallback((list) => {
    return list.map((item, i) => {
      return <option value={item.id} key={i}>{item.name}</option>
    });
  }, []);

  return (
    <>
      {(method === 'create') ?
        <>
          <Col md="4">
            <FormGroup>
              <Label>Bölgə</Label>
              <Input
                type="select"
                onChange={(e) => onChangeInputData('region', e.target.value)}
                className="form-control form-control-sm"
                value={region ?? ''}
              >
                <option value="" defaultValue={!region}>-------</option>
                {getOptions(regionList)}
              </Input>
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>Bina</Label>
              <Input
                type="select"
                onChange={(e) => onChangeInputData('house', e.target.value)}
                className="form-control form-control-sm"
                value={house ?? ''}
              >
                <option value="" defaultValue={!house}>-------</option>
                {getOptions(houseList)}
              </Input>
            </FormGroup>
          </Col>

        </> :

        <Row>
          <Col md="4" className="mb-2">Bölgə:</Col>
          <Col md="8" className="mb-3">
            <Input
              type="select"
              onChange={(e) => onChangeInputData('region', e.target.value)}
              className="custom-form-control-sm"
              disabled={disabled}
              value={region ?? ''}
            >
              <option value="" defaultValue={!region}>-------</option>
              {getOptions(regionList)}
            </Input>
          </Col>
          <Col md="4" className="mb-2">Bina:</Col>
          <Col md="8" className="mb-3">
            <Input
              type="select"
              onChange={(e) => onChangeInputData('house', e.target.value)}
              className="custom-form-control-sm"
              disabled={disabled}
              value={house ?? ''}
            >
              <option value="" defaultValue={!house}>-------</option>
              {getOptions(houseList)}
            </Input>
          </Col>
        </Row>
      }
    </>
  );
});



export default Address;
