
import Dashboard from "views/finance/Dashboard";
import PayerPopulation from "views/finance/PayerPopulation";
import PayerNonPopulation from "views/finance/PayerNonPopulation";
import PayerDetail from "views/finance/PayerDetail";
import Inspector from "views/finance/Inspector";
import InspectorMap from "views/finance/Map";
import InspectorDetail from "views/finance/InspectorDetail";
import ActivityLog from "views/finance/ActivityLog";
import AddPayer from "views/finance/AddPayer";

export const routesComponent = [
  {
    path: "/dashboard",
    component: Dashboard,
    layout: "/finance",
  },
  {
    path: "/payers/population",
    component: PayerPopulation,
    layout: "/finance",
  },
  {
    path: "/payers/non-population",
    component: PayerNonPopulation,
    layout: "/finance",
  },
  {
    path: "/payers/population-add",
    component: AddPayer,
    layout: "/finance",
  },
  {
    path: "/payers/non-population-add",
    component: AddPayer,
    layout: "/finance",
  },
  {
    path: "/payers/population/:id",
    component: PayerDetail,
    layout: "/finance",
  },
  {
    path: "/payers/non-population/:id",
    component: PayerDetail,
    layout: "/finance",
  },
  {
    path: "/inspectors/population",
    component: Inspector,
    layout: "/finance",
  },
  {
    path: "/inspectors/non-population",
    component: Inspector,
    layout: "/finance",
  },
  {
    path: "/inspectors/population/:id",
    component: InspectorDetail,
    layout: "/finance",
  },
  {
    path: "/inspectors/non-population/:id",
    component: InspectorDetail,
    layout: "/finance",
  },
  {
    path: "/map",
    component: InspectorMap,
    layout: "/finance",
  },
  {
    path: "/history",
    component: ActivityLog,
    layout: "/finance",
  },
];

var routes = [
  {
    path: "/dashboard",
    name: "Ümumi baxış",
    icon: "fa fa-home",
    layout: "/finance",
    dropdown: false,
  },
  {
    path: "/payers",
    name: "Abonentlər",
    icon: "fas fa-user-friends",
    dropdown: true,
    layout: "/finance",
    iconClosed: "fa fa-angle-right",
    iconOpened: "fa fa-angle-down",
    subNav: [
      {
        name: "Əhali",
        path: "/payers/population",
        layout: "/finance",
        icon: "fas fa-user",
      },
      {
        name: "Kommersiya",
        path: "/payers/non-population",
        layout: "/finance",
        icon: "fas fa-user-tie",
      }
    ],
  },
  {
    path: "/inspectors",
    name: "Müfəttişlər",
    icon: "far fa-address-card",
    dropdown: true,
    layout: "/finance",
    iconClosed: "fa fa-angle-right",
    iconOpened: "fa fa-angle-down",
    subNav: [
      {
        name: "Əhali",
        path: "/inspectors/population",
        layout: "/finance",
        icon: "fas fa-user",
      },
      {
        name: "Kommersiya",
        path: "/inspectors/non-population",
        layout: "/finance",
        icon: "fas fa-user-tie",
      }
    ],
  },
  {
    path: "/map",
    name: "Müfəttişlərin fəaliyyəti",
    icon: "fa fa-map-marked-alt",
    layout: "/finance",
    dropdown: false,
  },
  {
    path: "/history",
    name: "Əməliyyat tarixçəsi",
    icon: "fa fa-list",
    layout: "/finance",
    dropdown: false,
  },
];

export default routes;
