import { PAYERPOPULATION } from "../../api/urls";
import { FETCH_PAYERPOPULATION_SUCCESS, FETCH_PAYERPOPULATION_FAIL, FETCH_PAYERPOPULATION_INPUT_DATA } from "./actionTypes";

export const changeInputData = (column = null, search = null) => {
  return dispatch => {
    const obj = {
      type: FETCH_PAYERPOPULATION_INPUT_DATA,
      data: {},
    }
    if (column) {
      obj.data[column] = search;
      console.log(search);
    }
    dispatch(obj);
  }
}

const payerPopulationData = (url = null, page = 1) => {
  return dispatch => {
    const URL = (url) ? url : PAYERPOPULATION + `&page=${page}`;
    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_PAYERPOPULATION_SUCCESS,
          data: { ...data, page: page },
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_PAYERPOPULATION_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}


export const payerPopulationDataExcel = (url, page = 1) => {
  return dispatch => {
    const URL = (url) ? url + '&export=true' : PAYERPOPULATION + `&page=${page}&export=true`;
    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
      }
    })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Hesabat_YVOK_${new Date().toJSON().slice(0, 10)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(err => {
        return dispatch({
          type: FETCH_PAYERPOPULATION_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}

export default payerPopulationData;
