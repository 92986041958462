import React, { useState, useCallback } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  // Row,
  // Col,
} from "reactstrap";

import SubMenu from "./SubMenu";
import { useSelector } from 'react-redux';
import { strToUpper } from 'helpers/utils';

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const showLog = useSelector(state => state.company.showLog);
  const showMap = useSelector(state => state.company.showMap);
  const isAdmin = useSelector(state => state.company.isAdmin);
  const isOpen = useSelector(state => state.navbarToggle.isOpen);
  const { logo } = props;
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const createLinks = useCallback((routes) => {
    return routes.map((prop, key) => {

      if (prop.path === "/map" && !isAdmin && !showMap) return null;
      if (prop.path === "/history" && !isAdmin && !showLog) return null;

      if (prop.dropdown) {
        return (
          <SubMenu {...props} {...prop} key={key} onClick={closeCollapse} activeClassName="active" last={routes.length === key + 1} />
        );
      }

      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            <span className={(!isOpen) ? "custom-d-none" : ""}>{prop.name}</span>
          </NavLink>
          {routes.length !== key + 1 && <hr className="my-1" />}
        </NavItem>
      );

    });
  }, [showLog, showMap, isAdmin, props, isOpen])

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className={(props.isOpen) ?
        "navbar-vertical fixed-left navbar-light bg-white extra-sidebar-style"
        : "navbar-vertical fixed-left navbar-light bg-white custom-width extra-sidebar-style"}
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler custom-navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <svg xmlns="http://www.w3.org/2000/svg"
            width="100%" height="100%"
            viewBox="0 0 24 24"
            fill="currentColor">
            <g data-name="Layer 2">
              <g data-name="menu-2">
                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"></rect>
                <circle cx="4" cy="12" r="1"></circle><rect x="7" y="11" width="14" height="2" rx=".94" ry=".94"></rect>
                <rect x="3" y="16" width="18" height="2" rx=".94" ry=".94"></rect><rect x="3" y="6" width="18" height="2" rx=".94" ry=".94">
                </rect></g></g></svg>
        </button>

        {logo ? (
          <NavbarBrand className="pt-0 ml-auto mr-auto" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img d-inline"
              src={logo.imgSrc}
              width={"25px"}
            />
            <h4 className="pl-3 d-inline">{strToUpper(props.companyName)}</h4>
          </NavbarBrand>
        ) : null}


        <Nav className="align-items-center d-md-none">
          <Link to="/auth/logout" className="pr-3">
            <i className="ni ni-button-power " />
          </Link>
        </Nav>

        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="pb-3 d-md-none">
            <button
              className="navbar-toggler float-right"
              type="button"
              onClick={toggleCollapse}
            >
              <span />
              <span />
            </button>
          </div>

          <Nav navbar className="custom-sidebar-item">{createLinks(props.routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};



export default Sidebar;
