import { updateObject } from '../../shared/utility';
import { SET_INITIAL_SOCKET } from '../actions/actionTypes';


const initialState = {
  socket: undefined,
  onInspectors: false,
  onDashboard: false,
  error: null,
};

const reducer = (state = initialState, action) => {

  if (action.type === SET_INITIAL_SOCKET) {
    console.log('I am in restore');
    return updateObject(state, initialState);
  }

  return updateObject(state, action.data);
};

export default reducer;
