import React, { useState, useEffect } from "react";

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  NavLink,
  FormGroup,
  Form,
  Row,
  Col,
  Label,
  Input,
  Button
} from 'reactstrap';

import { useLocation, Link, useParams } from "react-router-dom";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import DateSelector from "components/DateSelector";

// import moment from 'moment';

import { documentDetail, saveEditedDocument } from 'store/actions/document';

import { store } from 'react-notifications-component';

import { BASE_URL } from 'api/urls';

import { fileNames } from 'helpers/utils';

import { setTypesAndTags } from 'store/actions/document';

import { useSelector, useDispatch } from 'react-redux';

import Select from 'react-select';

import { GENERAL_DEPARTMENT, LAW_DEPARTMENT, ORGANIZATIONAL_DEPARTMENT } from 'helpers/utils';

const notficationOptions = {
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 2000,
    onScreen: true
  }
}

const EditDocument = props => {
  const location = useLocation();
  const { basePath } = props;

  const types = useSelector(state => state.document.types);
  const tags = useSelector(state => state.document.tags);
  const fetched = useSelector(state => state.document.fetched);
  const dispatch = useDispatch();

  const [title, setTitle] = useState(null);
  const [date, setDate] = useState(null);
  const [document, setDocument] = useState(null);
  const [number, setNumber] = useState(null);
  const [sender, setSender] = useState(null);
  const [representative, setRepresentative] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const [note, setNote] = useState(null);
  const [data, setData] = useState(null);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [btnDisable, setBtnDisable] = useState(null);
  const [status, setStatus] = useState(null);
  const [archiveData, setArchiveData] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [group, setGroup] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (!fetched) {
      dispatch(setTypesAndTags())
    }
  }, [dispatch, fetched]);

  useEffect(() => {
    if(!basePath || !id) return;
    const url = `${BASE_URL}${basePath}/document/${id}/`;
    let isMounted = true;

    documentDetail(url)
      .then(resp => resp.json())
      .then(data => {
        if(isMounted){
          setTitle(data.title);
          setDate(data.date);
          setNote(data.note);
          setCurrentFiles(data.documents);
          setStatus(data.status);
          setGroup(data.group_name);
          setSender(data.sender);
          setRepresentative(data.representative);
          setRecipient(data.recipient);

          let tags = data.current_tags ?? [];
          let types = data.current_types ?? [];
          let editable = data.status === 2;

          setSelectedTypes(types.map(type => {
            return {
              value: type.id,
              label: type.name,
              isDisabled: !editable
            }
          }))

          setSelectedTags(tags.map(tag => {
            return {
              value: tag.id,
              label: tag.name,
              isDisabled: !editable
            }
          }));


          if (data.status === 3) {
            setArchiveData({
              executedDate: data.executed_date,
              executedPeriod: data.executed_period,
              archiveDate: data.status_update
            });
          }else {
            setArchiveData(null);
          }
        }
        return () => {
          isMounted = false;
        }
      })
      .catch(err => console.error(err));
  }, [basePath, id]);


  useEffect(() => {
    if (basePath && data) {
      const url = `${BASE_URL}${basePath}/document/${id}/`;
      setBtnDisable(true);
      let isMounted = true;
      saveEditedDocument({data, url})
        .then(resp => {
          if (!resp.ok) {
            throw new Error(resp.status);
          }

          store.addNotification({
            title: "Uğurlu əməliyyat!",
            message: 'Dəyişiklər müvəffəqiyyətlə yadda saxlanıldı.',
            type: 'success',
            ...notficationOptions,
          });

          if (isMounted) {
            setData(null);
            setBtnDisable(false);
          }
          return () => {isMounted = false};
        })
        .catch(err => {
          console.error(err);

          store.addNotification({
            title: "Uğursuz əməliyyat!",
            message: 'Xəta baş verdi.',
            type: 'danger',
            ...notficationOptions,
          });

          if (isMounted) {
            setData(null);
            setBtnDisable(false);
          }
          return () => {isMounted = false};
        })
    }
  }, [basePath, data, setData, setBtnDisable, id]);


  const editable = status === 2;

  const filesList = currentFiles.map((currentFile, index) => {
    return <li key={index}>
      <a href={currentFile} target="_blank" rel="noreferrer">{currentFile}</a>
    </li>
  });

  const typeOptions = types.map(type => {
    return {
      value: type.id,
      label: type.name,
      isDisabled: !editable
    }
  });

  const tagOptions = tags.map(tag => {
    return {
      value: tag.id,
      label: tag.name,
      isDisabled: !editable
    }
  });

  const tagResult = selectedTags.map(tag => tag.value);
  const typeResult = selectedTypes.map(type => type.value);

  let general = basePath === 'general';


  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <Link color="inherit" to={location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1)} className="p-0 nav-link">
          Sənədlər
        </Link>
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          { title }
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow">
        <CardHeader className="pr-5 pl-5">
          <Row>
            <Col>
              <h2 className="mb-0 d-flex">{ title }</h2>
            </Col>
            {editable && <Col>
              <Button
                className="btn float-right clear-fix"
                color="info"
                disabled={btnDisable}
                type="submit"
                form="form-edit"
              >
                <i className="mr-1 fas fa-save"></i> Yadda saxla
              </Button>
            </Col>}
          </Row>
        </CardHeader>
        <CardBody className="pr-5 pl-5">
          <Form id="form-edit" onSubmit={(e) => {
            e.preventDefault();
            if (document || note) {
              let formData = {
                document, note, sender,
                representative, number,
                recipient,
                tags: tagResult,
                types: typeResult,
              };
              if (general) formData.group = group;
              setData(formData);
            }
          }}>
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Sənədin adı</Label>
                  <Input
                    placeholder="Sənədin adı"
                    type="text"
                    className="form-control"
                    value={title ?? ''}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Sənədin nömrəsi</Label>
                  <Input
                    placeholder="Sənədin nömrəsi"
                    type="text"
                    className="form-control"
                    onChange={(e) => setNumber(e.target.value)}
                    value={number ?? ''}
                    readOnly={!editable}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" xl="4">
                <Label>Tarix</Label>
                <DateSelector
                  value={date}
                  readOnly={true}
                />
              </Col>
              {general &&
              <Col md="6" xl="4">
                <Label>Şöbə *</Label>
                <Input type="select"
                  onChange={(e) => setGroup(e.target.value)}
                  className="form-control"
                  value={group}
                  readOnly={!editable}
                  >
                  <option value={GENERAL_DEPARTMENT}>Ümumi şöbə</option>
                  <option value={LAW_DEPARTMENT}>Hüquq şöbəsi</option>
                  <option value={ORGANIZATIONAL_DEPARTMENT}>Təşkilat şöbəsi</option>
                </Input>
              </Col>}
            </Row>
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Göndərən qurumun adı</Label>
                  <Input
                    placeholder="Göndərən qurumun adı"
                    type="text"
                    className="form-control"
                    onChange={(e) => setSender(e.target.value)}
                    value={sender ?? ''}
                    readOnly={!editable}
                  />
                </FormGroup>
              </Col>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Qurumun nümayəndəsi</Label>
                  <Input
                    placeholder="Qurumun nümayəndəsi"
                    type="text"
                    className="form-control"
                    onChange={(e) => setRepresentative(e.target.value)}
                    value={representative ?? ''}
                    readOnly={!editable}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Kim qəbul edib</Label>
                  <Input
                    placeholder="Kim qəbul edib"
                    type="text"
                    className="form-control"
                    onChange={(e) => setRecipient(e.target.value)}
                    value={recipient ?? ''}
                    readOnly={!editable}
                  />
                </FormGroup>
              </Col>
            </Row>
            {editable && <Row>
              <Col md="8" xl="8">
                <FormGroup>
                  <Label>Fayl seçin</Label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFileLang"
                      lang="en"
                      multiple
                      onChange={(e) => {
                        setDocument(e.target.files);
                      }}
                    />
                    <label className="custom-file-label" htmlFor="customFileLang" data-browse="Axtar">{document ? fileNames(document) : 'Fayl seçin'}</label>
                  </div>

                </FormGroup>
              </Col>
            </Row>}
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Etiket</Label>
                  <Select
                    options={tagOptions} isMulti
                    onChange={(values) => setSelectedTags(values)}
                    placeholder="Seçin"
                    value={selectedTags}
                    isDisabled={!editable}/>
                </FormGroup>
              </Col>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Sənəd növü</Label>
                  <Select
                    options={typeOptions} isMulti
                    onChange={(values) => setSelectedTypes(values)}
                    placeholder="Seçin"
                    value={selectedTypes}
                    isDisabled={!editable}/>
                </FormGroup>
              </Col>
              <Col md="8">
                <FormGroup>
                  <Label>Qeyd</Label>
                  <Input
                    placeholder="Qeyd"
                    type="textarea" row={4}
                    className="form-control"
                    value={note ?? ''}
                    onChange={(e) => setNote(e.target.value)}
                    readOnly={!editable}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Fayllar</Label>
                  <ul>
                    {filesList}
                  </ul>
                </FormGroup>
              </Col>
              {archiveData && <Col md="6" xl="4">
                <FormGroup>
                  <Label>Tarixcə: </Label>
                  <ul>
                    <li><b>Qəbul olundu: </b> {date} </li>
                    <li><b>İcraya verildi: </b> {archiveData.executedDate} </li>
                    <li><b>Icra müddəti: </b> {archiveData.executedPeriod ? `${archiveData.executedPeriod} gün` : "Gün ərzində" } </li>
                    <li><b>Arxivlənmə tarixi: </b> { archiveData.archiveDate } </li>
                  </ul>
                </FormGroup>
              </Col>}
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}



export default EditDocument;
