import { INSPECTOR_DETAIL } from "../../api/urls";
import {
  FETCH_INSPECTOR_SUCCESS, FETCH_INSPECTOR_FAIL, FETCH_INSPECTOR_INPUT_DATA
} from "./actionTypes";

export const changeInputData = (column = null, search = null) => {
  return dispatch => {
    const obj = {
      type: FETCH_INSPECTOR_INPUT_DATA,
      data: {},
    }
    if (column) {
      obj.data[column] = search;
    }
    dispatch(obj);
  }
}


export const inspectorData = (id = null, type = 'payment', url = null, page = 1) => {
  return dispatch => {
    let URL = (url) ? url : INSPECTOR_DETAIL + `${id}/${type}/?limit=100&page=${page}`;
    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_INSPECTOR_SUCCESS,
          data: { ...data, page: page },
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_INSPECTOR_FAIL,
          data: {
            error: err,
          }
        });
      });
  }

}


export const setActivePage = (type) => {
  return dispatch => {
    dispatch({
      type: FETCH_INSPECTOR_SUCCESS,
      data: {
        activePage: type,
        count: null,
        next: null,
        pages: null,
        previous: null,
        results: null,
        page: 1,
      },
    });
  }
}


export const changePage = (page) => {
  return dispatch => {
    dispatch({
      type: FETCH_INSPECTOR_SUCCESS,
      data: {
        page: page,
      }
    });
  }
}


export const inspectorDataExcel = (id = null, type = 'payment', url = null, page = 1) => {
  return dispatch => {
    const URL = (url) ?
      url + '&export=true' : INSPECTOR_DETAIL + `${id}/${type}/?limit=100&page=${page}&export=true`;

    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        // 'Content-Type': 'application/json',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Hesabat_${id}_${new Date().toJSON().slice(0, 10)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(err => {
        return dispatch({
          type: FETCH_INSPECTOR_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}


const inspectorDetail = (id) => {
  return dispatch => {
    const URL = INSPECTOR_DETAIL + `${id}/`;
    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_INSPECTOR_SUCCESS,
          data: {
            id: data.id,
            fullName: data.full_name,
            inspectorImage: data.photo,
            inspectorPhone: data.phone,
          },
        });
      })
      .catch(err => {
        return dispatch({
          type: FETCH_INSPECTOR_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}


export default inspectorDetail;
