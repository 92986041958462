import React, { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import payerDetailData, { changeInputData, payerDebt, updatePayerData, changeNotificationStatus, deletePayer } from '../../store/actions/payerDetail';
import { setInitial } from '../../store/actions/index';
import { getPrivilegesType } from 'store/actions/company';
//import {DEBT} from "../../api/urls";
import { useParams, useLocation } from "react-router-dom";
// import { strToUpper } from 'helpers/utils';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// import {NotificationManager} from 'react-notifications';
import { store } from 'react-notifications-component';
import { notificationOptions } from 'helpers/utils';
import { PAYER_DETAIL } from "api/urls";
import DeleteModal from 'helpers/deleteModal';
import EditModal from 'helpers/editModal';
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  NavLink,
  Button,
  Input,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form
} from "reactstrap";

import { btnDisabledEvent } from "store/actions/addPayer";

import AddDebt from "../../components/Payer/AddDebt";

import Address from 'components/Payer/Address';


const PopulationDetail = (props) => {

  const { id } = useParams();
  const location = useLocation();

  const pageHeader = (location.pathname.includes('non-population')) ? "Abonentlər Kommersiya" : "Abonentlər Əhali";

  const {
    onGetPayerData, onSetInitial, onPayerDebt, onUpdateData,
    onChangeInputData, notificationStatus, changeNotfStatus,
    onGetPrivilegesType, privilegesType, isAdmin, edit,
    btnDisabled, onBtnDisabledEvent, deletePerm, history, region, house
  } = props;

  useEffect(() => {
    onSetInitial();
    onGetPayerData(id);
    onPayerDebt(id);
  }, [onGetPayerData, id, onSetInitial, onPayerDebt]);

  const [modal, setModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deptId, setDeptId] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [fullName, setFullName] = useState(null);
  const [debtData, setDebtData] = useState({
    service: 0,
    main_amount: 0,
    final_amount: 0,
    id: 0,
  });

  useEffect(() => {
    if (props.fullName) {
      setFullName(props.fullName)
    }
  }, [props.fullName])

  const modalToggle = useCallback(() => setModal(!modal), [modal]);

  const deleteModalToggle = useCallback(() => setDeleteModal(!deleteModal), [deleteModal]);

  const editModalToggle = useCallback(() => setEditModal(!editModal), [editModal]);

  const deleteDebtRequest = useCallback(async (debtId) => {
    const URL = PAYER_DETAIL + `${id}/debts/${debtId}/`;
    onBtnDisabledEvent(true);
    const response = await fetch(URL, {
      method: "DELETE",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });

    if ([200, 201].includes(response.status)) {
      store.addNotification({
        ...notificationOptions,
        title: "Uğurlu əməliyyat!",
        message: 'Borc məlumatı müvəffəqiyyətlə silindi.',
        type: 'success',
      });
      onBtnDisabledEvent(false);
      deleteModalToggle();
      onGetPayerData(id);
      onPayerDebt(id);
    } else {
      store.addNotification({
        ...notificationOptions,
        title: "Uğursuz əməliyyat!",
        message: 'Xəta baş verdi..',
        type: 'danger',
      });
      onBtnDisabledEvent(false);
    }
  }, [id, onGetPayerData, onPayerDebt, onBtnDisabledEvent, deleteModalToggle]);

  const deleteDebtBtnClick = useCallback((id) => {
    setDeptId(id);
    deleteModalToggle();
  }, [setDeptId, deleteModalToggle]);


  const editDebtBtnClick = useCallback((debt) => {
    setDebtData(debt);
    editModalToggle();
  }, [editModalToggle, setDebtData]);


  const getRows = useCallback((results, isAdmin, edit, btnDisabled, deleteDebtBtnClick, editDebtBtnClick, privilege) => {
    if (results) {
      return results.map((row, key) => {
        const _style = {};
        if (privilege && row.type === 1) _style.textDecoration = 'line-through';
        let type = (row.type === 2) ? <Badge color="success">ÖDƏNİŞ</Badge> : <Badge color="danger">BORC</Badge>;
        let amount = (row.final_amount) ? row.final_amount : row.main_amount;
        amount = (row.type === 2) ? amount * -1 : amount;
        return (
          <tr key={key} style={_style}>
            <td>{row.service_name}</td>
            <td>{amount}</td>
            <td>{type}</td>
            <td>{row.created_at}</td>
            {(isAdmin || edit) ?
              <td className="text-center">
                {(row.type !== 2 && !privilege) ?
                  <>
                    <i className="fas fa-edit text-warning mr-2 hover-pointer" disabled={btnDisabled} onClick={() => editDebtBtnClick({
                      id: row.id,
                      service: row.service,
                      main_amount: row.main_amount,
                      final_amount: (row.final_amount - row.main_amount).toFixed(2),
                    })}></i>
                    <i className="fas fa-trash-alt text-danger hover-pointer" disabled={btnDisabled} onClick={() => deleteDebtBtnClick(row.id)}></i>
                  </> : null
                }
              </td> : null
            }
          </tr>
        );
      });
    }
    return null;
  }, []);

  const modalRows = useCallback(() => {
    if (props.results) {
      return props.results.map((row, key) => {
        let type = (row.type === 2) ? <Badge color="success">ÖDƏNİŞ</Badge> : <Badge color="danger">BORC</Badge>;
        let amount = (row.final_amount) ? row.final_amount : row.main_amount;
        amount = (row.type === 2) ? amount * -1 : amount;
        return <li key={key}>{row.service_name} {type} {amount} ₼</li>;
      });
    }
    return null;
  }, [props.results]);


  const deleteBtnClick = useCallback(async () => {
    if (isAdmin || deletePerm) {
      const status = await deletePayer(id);

      console.log(status);
      if (status === 200) {
        store.addNotification({
          ...notificationOptions,
          title: "Uğurlu əməliyyat!",
          message: 'Abonent müvəffəqiyyətlə silindi.',
          type: 'success',
        });

        history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1));

      } else {
        store.addNotification({
          ...notificationOptions,
          title: "Uğursuz əməliyyat!",
          message: 'Xəta baş verdi.',
          type: 'danger',
        });
      }
    }
  }, [isAdmin, deletePerm, id, location, history]);



  const shortName = useCallback(() => {
    let name = '';
    if (props.fullName) {
      const fn = props.fullName.split(" ");
      for (let i = 0; i < fn.length; i++) {
        if (i < 2) {
          name += fn[i].toUpperCase().charAt(0);
        }
      }
    }
    return name;
  }, [props.fullName]);

  // document.title = (props.companyName) ? strToUpper(props.companyName) + " | " + strToUpper(pageHeader) : "ERP";


  const previousClick = () => {
    if (props.previous) {
      onPayerDebt(id, props.previous, props.page - 1);
    }
  }

  const nextClick = () => {
    if (props.next) {
      onPayerDebt(id, props.next, props.page + 1);
    }
  }


  const paginationButtonClick = useCallback((p) => {
    onPayerDebt(id, null, p);
  }, [onPayerDebt, id]);


  const getPaginations = useCallback(() => {
    if (!props.pages) {
      return null;
    }
    const showPages = 5;
    let start = 1;
    let finish = showPages;
    const data = [];
    if (props.page > 2 && props.pages > showPages) {
      start = props.page - 1;
      finish = (props.page + (showPages - 2) < props.pages) ? props.page + (showPages - 2) : props.page;
    } else if (props.pages < showPages) {
      finish = props.pages;
    }
    for (let i = 0; i <= finish - start; i++) {
      data.push(
        (
          <PaginationItem key={i} className={(i + start === props.page) ? "active" : ""}>
            <PaginationLink
              href=""
              onClick={() => paginationButtonClick(i + start)}
            >
              {i + start}
            </PaginationLink>
          </PaginationItem>
        )
      );
    }

    return data;
  }, [props.pages, props.page, paginationButtonClick])


  const getData = () => {
    const obj = {
      full_name: fullName,
      member_id: props.memberId,
      address: props.address,
      residential_area: props.residential_area,
      land_area: props.land_area,
      region: region,
      house: house,
    };

    if (props.privilege) {
      obj.privilege = props.privilege;
    }
    return obj;
  };

  const getOptions = useCallback(() => {
    if (!privilegesType) {
      onGetPrivilegesType();
      return null;
    } else {
      return privilegesType.map((type, i) => {
        if (props.privilege && props.privilege === type.id) {
          return <option value={type.id} selected key={i}>{type.name}</option>
        }

        return <option value={type.id} key={i}>{type.name}</option>
      });
    }
  }, [onGetPrivilegesType, privilegesType, props.privilege]);


  const onDetailUpdate = (e) => {
    e.preventDefault();
    onBtnDisabledEvent(true);
    onUpdateData(id, getData());
    onChangeInputData('full_name', fullName);
  };


  useEffect(() => {
    if (notificationStatus) {
      if (notificationStatus === 'success') {
        // NotificationManager.success('Dəyişiklər müvəffəqiyyətlə yadda saxlanıldı.', 'Uğurlu əməliyyat!');
        store.addNotification({
          ...notificationOptions,
          title: "Uğurlu əməliyyat!",
          message: 'Dəyişiklər müvəffəqiyyətlə yadda saxlanıldı.',
          type: 'success',
        });
        changeNotfStatus();
      }
      else if (notificationStatus === 'error') {
        // NotificationManager.error('Xəta baş verdi.', 'Uğursuz əməliyyat');
        store.addNotification({
          ...notificationOptions,
          title: "Uğursuz əməliyyat!",
          message: 'Xəta baş verdi.',
          type: 'danger',
        });
        changeNotfStatus();
      }
    }
  }, [notificationStatus, changeNotfStatus]);


  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" onClick={() => history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1))} className="p-0 cursor-pointer">
          {pageHeader}
        </NavLink>
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          {props.fullName}
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow">
        <CardHeader className="border-0">
          <h2 className="mb-0 d-flex" >
            <span className="profile-rounded-circle">
              {shortName()}
            </span>
            <span className="profile-circle-name">{props.fullName}</span>
          </h2>
        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <Form onSubmit={onDetailUpdate}>
            <Row>
              <Col md="4">
                <Row>
                  <Col md="4" className="mb-2">Ad, Soyad, Ata adı:</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Ad, Soyad, Ata adı"
                      type="text"
                      className="custom-form-control-sm"
                      onChange={(e) => { if (isAdmin) setFullName(e.target.value); }}
                      value={fullName ?? ''}
                      required
                      disabled={!isAdmin} />
                  </Col>
                  <Col md="4" className="mb-2">YVÖK:</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="YVÖK"
                      type="text"
                      className="custom-form-control-sm"
                      onChange={(e) => { if (isAdmin || edit) onChangeInputData('member_id', e.target.value); }}
                      value={(props.memberId) ? props.memberId : ''}
                      required
                      disabled={(!isAdmin && !edit) ? true : false} />
                  </Col>
                  <Col md="4" className="mb-2">Ünvan:</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Ünvan"
                      type="textarea" row={2}
                      className="custom-form-control-sm"
                      value={(props.address) ? props.address : ''}
                      onChange={(e) => { if (isAdmin || edit) onChangeInputData('address', e.target.value); }}
                      required
                      disabled={(!isAdmin && !edit) ? true : false} />
                  </Col>
                  <Col md="4" className="mb-2">Sahəsi (m<sup>2</sup>):</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Sahəsi"
                      type="number"
                      className="custom-form-control-sm"
                      value={(props.residential_area) ? props.residential_area : ''}
                      onChange={(e) => { if (isAdmin || edit) onChangeInputData('residential_area', e.target.value !== '' ? e.target.value : null); }}
                      disabled={(!isAdmin && !edit) ? true : false} />
                  </Col>
                  <Col md="4" className="mb-2">Torpaq sahəsi (ha):</Col>
                  <Col md="8" className="mb-3">
                    <Input
                      placeholder="Torpaq sahəsi"
                      type="number"
                      className="custom-form-control-sm"
                      value={(props.land_area) ? props.land_area : ''}
                      onChange={(e) => { if (isAdmin || edit) onChangeInputData('land_area', e.target.value !== '' ? e.target.value : null); }}
                      disabled={(!isAdmin && !edit) ? true : false} />
                  </Col>
                </Row>
              </Col>
              <Col md="4">
                <Row>
                  <Col md="4" className="mb-2">İmtiyaz: </Col>
                  <Col md="8" className="mb-3">
                    <Input type="select" name="select"
                      onChange={(e) => { if (isAdmin || edit) onChangeInputData('privilege', e.target.value); }}
                      className="custom-form-control-sm" disabled={(!isAdmin && !edit) ? true : false}>
                      <option value="" defaultValue>-------</option>
                      {getOptions()}
                    </Input>
                    <small style={{
                      fontSize: '0.7rem',
                      lineHeight: 'normal',
                    }}>* İmtiyaz seçilmiş aboent məlumatları saxlanıldıqda həmin abonent üçün bütün borc məlumatları silinəcəkdir.</small>
                  </Col>
                  <Col md="4" className="mb-2">Xalis borc:</Col>
                  <Col md="8" className="mb-3">
                    <Input placeholder="Xalis borc" type="text" className="custom-form-control-sm" value={`${(props.main_amount) ? props.main_amount : 0} ₼`} disabled />
                  </Col>
                  <Col md="4" className="mb-2">Yekun borc:</Col>
                  <Col md="8" className="mb-3">
                    <Input placeholder="Yekun borc" type="text" className="custom-form-control-sm" value={`${(props.final_amount) ? props.final_amount : 0} ₼`} disabled />
                  </Col>
                  <Col md="4" className="mb-2">Ümumi borc:</Col>
                  <Col md="8" className="mb-3">
                    <Input placeholder="Ümumi borc" type="text" className="custom-form-control-sm" value={`${(props.total_amount) ? props.total_amount : 0} ₼`} disabled />
                  </Col>
                </Row>

              </Col>
              <Col md="4">
                <Address region={region} house={house} onChangeInputData={onChangeInputData} method='edit' disabled={(!isAdmin && !edit) ? true : false} />
              </Col>

            </Row>
            <Row className="pt-2">
              <Col md="6" className="mt-2">
                {(isAdmin || deletePerm) &&
                  <Button className="btn custom-btn-style"
                    color="danger"
                    onClick={modalToggle}
                  >
                    Abonenti sil
                  </Button>}
              </Col>
              <Col md="6" className="mt-2">
                <Button className="btn custom-btn-style custom-float-btn"
                  color="info"
                  type="submit"
                  disabled={(!isAdmin && !edit) ? true : btnDisabled}
                >
                  Yadda saxla
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>

      <Card className="shadow mt-3 mb-5">
        <CardHeader>
          <h2 className="">
            Borc/Ödəniş məlumatları
          </h2>
        </CardHeader>
        {(props.results && props.results.length) ?
          <CardBody>


            <Table className="align-items-center table-striped table-hover custom-table-style table-bordered" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Vergi növü</th>
                  <th scope="col">Məbləğ (₼)</th>
                  <th scope="col">Borc / Ödəniş</th>
                  <th scope="col">Yaranma tarixi</th>
                  {(isAdmin || edit) ?
                    <th scope="col">Əməliyyat</th> : null
                  }
                </tr>
              </thead>
              <tbody>
                {getRows(props.results, isAdmin, edit, btnDisabled, deleteDebtBtnClick, editDebtBtnClick, props.privilege)}
              </tbody>
            </Table>

            <nav aria-label="..." className="mt-2">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={(!props.previous) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={previousClick}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {getPaginations()}
                <PaginationItem className={(!props.next) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={nextClick}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardBody> : null
        }
        {(isAdmin || edit) && !props.privilege ?
          <CardFooter className="py-4">
            <AddDebt location={location} onPayerDebt={onPayerDebt} payerId={id} onGetPayerData={onGetPayerData} />
          </CardFooter> : null}
      </Card>
      {(isAdmin || deletePerm) &&
        <Modal
          isOpen={modal}
          toggle={modalToggle}
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
        >
          <ModalHeader toggle={modalToggle}>Abonent {props.fullName} silmək isdədiyinizə əminsinizmi?</ModalHeader>
          <ModalBody>
            <div className="py-2 text-center">
              <i className="fas fa-exclamation-triangle fa-3x"></i>
              <h3 className="mt-2 text-white">Abonentin silinməsi həmin abotentin borc/ödəniş məlumatlarını da təmizləyir.</h3>
            </div>
            {props.results &&
              <ul className="mt-1">
                {modalRows()}
              </ul>}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-white" color="default" onClick={deleteBtnClick}>Bəli, silinsin</Button>{' '}
            <Button className="text-white ml-auto" color="link" onClick={modalToggle}>Ləğv et</Button>
          </ModalFooter>
        </Modal>}

      {(isAdmin || edit) ?
        <DeleteModal
          modal={deleteModal}
          modalToggle={deleteModalToggle}
          deptId={deptId}
          deleteDebtRequest={deleteDebtRequest}
        />
        : null}

      {(isAdmin || edit) ?
        <EditModal
          payerId={id}
          onPayerDebt={onPayerDebt}
          onGetPayerData={onGetPayerData}
          location={location}
          modal={editModal}
          modalToggle={editModalToggle}
          values={debtData}
          setValues={setDebtData}
          onBtnDisabledEvent={onBtnDisabledEvent}
          btnDisabled={btnDisabled}
        /> : null
      }
    </Container>
  );
}




const mapStateToProps = state => {
  return {
    count: state.payerDetail.count,
    next: state.payerDetail.next,
    pages: state.payerDetail.pages,
    previous: state.payerDetail.previous,
    results: state.payerDetail.results,
    page: state.payerDetail.page,
    memberId: state.payerDetail.member_id,
    fullName: state.payerDetail.full_name,
    address: state.payerDetail.address,
    privilege: state.payerDetail.privilege,
    main_amount: state.payerDetail.main_amount,
    final_amount: state.payerDetail.final_amount,
    total_amount: state.payerDetail.total_amount,
    flat: state.payerDetail.flat,
    residential_area: state.payerDetail.residential_area,
    land_area: state.payerDetail.land_area,
    lived_people_count: state.payerDetail.lived_people_count,
    total: state.payerDetail.total,
    region: state.payerDetail.region,
    house: state.payerDetail.house,
    companyName: state.company.companyName,
    notificationStatus: state.payerDetail.notificationStatus,
    privilegesType: state.company.privilegesType,
    edit: state.company.edit,
    deletePerm: state.company.delete,
    //showLog: state.company.showLog,
    isAdmin: state.company.isAdmin,
    btnDisabled: state.payerDetail.btnDisabled,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetPayerData: (id) => dispatch(payerDetailData(id)),
    onChangeInputData: (column = null, value = null) => dispatch(changeInputData(column, value)),
    onPayerDebt: (id, url = null, page = 1) => dispatch(payerDebt(id, url, page)),
    onSetInitial: () => dispatch(setInitial()),
    onUpdateData: (id, data) => dispatch(updatePayerData(id, data)),
    changeNotfStatus: () => dispatch(changeNotificationStatus()),
    onGetPrivilegesType: () => dispatch(getPrivilegesType()),
    onBtnDisabledEvent: (s = false) => dispatch(btnDisabledEvent(s)),
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PopulationDetail);
