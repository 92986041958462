import React from "react";

import { Media } from 'reactstrap';

import { WEB_SOCKET } from 'api/urls';

import { store } from 'react-notifications-component';

import { shortName } from 'helpers/utils';



export const setSocket = ({ companyId, onSetNotificationTotal, onSetPaymentTotal }) => {

  if (!window.hasOwnProperty('socket') && companyId) {

    window.socket = window.io(WEB_SOCKET);

    window.socket.emit('joinCompany', {
      company_id: companyId,
    });

    window.socket.on('notification', (payload) => {
      onSetNotificationTotal(payload.data.total);
    });

    window.socket.on('payment', payload => {
      onSetPaymentTotal(payload.data.total);
    });


    window.socket.on('event', payload => {

      let type = (payload.data.type === 'payment') ? 'default' : 'success'
      let message =
        <Media className="align-items-center">
          <Media className="d-lg-block pr-3">
            {(payload.data.photo) ? <span className="avatar avatar-sm rounded-circle">
              <img
                alt="..."
                src={payload.data.photo}
              />
            </span> :
              <span className="profile-rounded-circle profile-rounded-circle-sm">
                {shortName(payload.data.inspector)}
              </span>
            }
          </Media>
          <Media className="d-lg-block">
            <span className="mb-0">
              {payload.data.inspector} tərəfindən {payload.data.amount} AZN {(payload.data.type === 'payment') ? 'ödəniş qəbzi' : 'bildiriş xəbərdarlıq'} təqdim olundu.
            </span>
          </Media>
        </Media>;


      store.addNotification({
        message: message,
        type: type,
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 10000,
          onScreen: true
        }
      });
    });

    window.socket.on('connect', () => {
      console.log('connected');
    });

    window.socket.on('disconnect', function () {
      console.log("disconnected");
    });

    window.socket.on('error', function () {
      console.log("error");
    });
  }
};
