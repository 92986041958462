import { DEBT, PAYER_DETAIL } from "../../api/urls";
import {
  FETCH_PAYER_SUCCESS, FETCH_PAYER_FAIL, FETCH_PAYER_INPUT_DATA,
  FETCH_DEBT_SUCCESS, FETCH_DEBT_FAIL
} from "./actionTypes";

export const changeInputData = (column = null, value = null) => {
  return dispatch => {
    const obj = {
      type: FETCH_PAYER_INPUT_DATA,
      data: {},
    }
    if (column) {
      obj.data[column] = value;
    }
    dispatch(obj);
  }
}


export const payerDebt = (id = null, url = null, page = 1) => {
  return dispatch => {
    let URL = (url) ? url : DEBT + `${id}/?limit=100&page=${page}`;
    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_DEBT_SUCCESS,
          data: { ...data, page: page },
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_DEBT_FAIL,
          data: {
            error: err,
          }
        });
      });
  }

}


const payerDetailData = (id) => {
  return dispatch => {
    const URL = PAYER_DETAIL + `${id}/`;
    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        if (!data.member_id) {
          try {
            delete data.member_id;
          } catch (e) {
            //
          }
        }
        dispatch({
          type: FETCH_PAYER_SUCCESS,
          data: {
            payerId: data.id,
            ...data
          },
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_PAYER_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}

export const updatePayerData = (id, updatedData) => {
  return dispatch => {
    const URL = PAYER_DETAIL + `${id}/`;
    if (updatedData && updatedData.member_id) {
      fetch(URL, {
        method: "PUT",
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData)
      }).then(resp => {
        if (resp.status === 200) {
          dispatch({
            type: "SUCCESS",
            data: {
              notificationStatus: 'success',
              // showError: false,
              btnDisabled: false,
            }
          })
        } else {
          dispatch({
            type: "ERROR",
            data: {
              // showError: true,
              notificationStatus: 'error',
              btnDisabled: false,
            }
          });
        }
      }).catch(err => {
        console.log(err);
      });
    }
  }
}

export const changeNotificationStatus = () => {
  return dispatch => {
    dispatch({
      type: 'SUCCESS',
      data: {
        // showError: false,
        notificationStatus: undefined,
      }
    });
  }
}

export const deletePayer = async (id) => {

  const URL = PAYER_DETAIL + `${id}/`;
  const response = await fetch(URL, {
    method: "DELETE",
    headers: {
      'Authorization': 'Token ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
    }
  });
  // const resp = await response.text();
  return response.status
}


// export const deletePayer = (id) => {

//     return async (dispatch) => {
//         const URL = PAYER_DETAIL + `${id}/`;
//         const response = await fetch(URL, {
//             method: "DELETE",
//             headers: {
//                 'Authorization': 'Token ' + localStorage.getItem('token'),
//                 'Content-Type': 'application/json',
//             }
//         });
//     }
// }


export default payerDetailData;
