import { ADD_PAYER_POP_API, ADD_PAYER_NON_POP_API } from "../../api/urls";

const addPayer = (postData, type = "population") => {
  const URL = (type === "population") ? ADD_PAYER_POP_API : ADD_PAYER_NON_POP_API;

  return dispatch => {
    fetch(URL, {
      method: "POST",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData)
    }).then(resp => {
      if ([200, 201].includes(resp.status)) {
        dispatch({
          type: "SUCCESS",
          data: {
            notificationStatus: 'success',
            btnDisabled: false,
          }
        })
      } else {
        dispatch({
          type: "ERROR",
          data: {
            // showError: true,
            notificationStatus: 'error',
            btnDisabled: false,
          }
        });
      }
    }).catch(err => {
      console.log(err);
      dispatch({
        type: "ERROR",
        data: {
          // showError: true,
          notificationStatus: 'error',
          btnDisabled: false,
        }
      });
    });
  }
}


export const updateDebts = (debts) => {
  return dispatch => {
    dispatch({
      type: "SUCCESS",
      data: {
        debts: debts,
      }
    });
  }
}


export const readyForFetch = (s) => {
  return dispatch => {
    dispatch({
      type: "SUCCESS",
      data: {
        savePayer: s
      }
    });
  }
}

export const btnDisabledEvent = (s = false) => {
  return dispatch => {
    dispatch({
      type: "SUCCESS",
      data: {
        btnDisabled: s
      }
    });
  }
}


export default addPayer;
