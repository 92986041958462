import { updateObject } from '../../shared/utility';

const initialState = {
  points: undefined,
  error: null,
  map: undefined,
};

const reducer = (state = initialState, action) => {
  return updateObject(state, action.data);
};

export default reducer;
