import { updateObject } from '../../shared/utility';

const initialState = {
  companyId: null,
  companyName: null,
  companyPhone: null,
  companyLogo: null,
  username: null,
  userFullName: null,
  userPhoto: null,
  privilegesType: null,
  services: null,
  isAdmin: false,
  showLog: false,
  showMap: false,
  edit: false,
  create: false,
  delete: false,
  groups: [],
  error: null,
  regions: [],
};

const reducer = (state = initialState, action) => {
  return updateObject(state, action.data);
};

export default reducer;
