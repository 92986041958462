import React, { useEffect, Suspense, Fragment } from 'react';
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import * as actions from './store/actions/index';
import Login from "./views/Login";
import Main from 'routes/Main';
import ReactNotification from 'react-notifications-component';


const App = () => {

  const isAuthenticated = useSelector(state => state.auth.token !== null);
  const loading = useSelector(state => state.auth.authLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.authCheckState());
  }, [dispatch]);

  let routes = (!loading) ? ((isAuthenticated) ?
    (<Main />) :
    (<Switch>
      <Route path="/auth/login" render={(props) => <Login {...props} />} />
      <Redirect from="/" to="/auth/login" />
    </Switch>)) :
    null;

  return (
    <Fragment>
      <ReactNotification />
      <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
    </Fragment>
  );
}

export default withRouter(App);
