import React, { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import inspectorData, { changeInputData, inspectorDataExcel, setDateProps } from '../../store/actions/inspector';
import { setInitial } from '../../store/actions/index';
import { INSPECTOR_POPULATION, INSPECTOR_NON_POPULATION } from "../../api/urls";
import { useLocation } from "react-router-dom";
import DatePicker from '../../components/DatePicker';
import { shortName } from 'helpers/utils';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import moment from 'moment';

// import {useHistory} from "react-router-dom";
import {
  //Badge,
  Card,
  CardHeader,
  CardFooter,
  //   DropdownMenu,
  //   DropdownItem,
  //   UncontrolledDropdown,
  //   DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  //   Progress,
  Table,
  Container,
  //   Row,
  //   UncontrolledTooltip,
  Button,
  Input,
  Row,
  Col,
  CardBody,
  NavLink,
} from "reactstrap";



const Inspector = (props) => {

  const { page, onChangeInputData, onGetInspectorData, onExcelExport, onSetInitial, onStartChange, onEndChange } = props;

  const location = useLocation();

  useEffect(() => {
    onSetInitial();
    onGetInspectorData(null, 1, location.pathname);
  }, [onGetInspectorData, location.pathname, onSetInitial]);

  const pageHeader = (location.pathname.includes('non-population')) ? "Müfəttişlər Kommersiya" : "Müfəttişlər Əhali";

  const [searchU, setSearch] = useState({
    url: null,
    column: null,
    value: null,
    page: false,
  });


  useEffect(() => {
    if (searchU.url === null || searchU.page) {
      return null;
    }
    onChangeInputData(searchU.column, searchU.value);
    const timeout = setTimeout(() => {
      onGetInspectorData(searchU.url, page, location.pathname)
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchU, page, onGetInspectorData, location.pathname, onChangeInputData]);

  const { history } = props;

  const handleRowClick = useCallback((id) => {
    let url = (location.pathname.slice(-1) === '/') ? `${location.pathname}${id}` : `${location.pathname}/${id}`;
    history.replace(url);
  }, [history, location.pathname]);




  const getRows = useCallback((results = null) => {
    if (results) {
      return results.map((row, key) => {
        return (
          <tr key={key} onClick={() => { handleRowClick(row.id) }}>
            <td className="text-truncate">
              <Media className="align-items-center">
                <Media className="d-lg-block pr-3">
                  {(row.photo) ? <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={row.photo}
                    />
                  </span> :
                    <span className="profile-rounded-circle profile-rounded-circle-sm">
                      {shortName(row.full_name)}
                    </span>
                  }
                </Media>
                <Media className="d-lg-block">
                  <span className="mb-0">
                    {row.full_name}
                  </span>
                </Media>
              </Media>
            </td>
            <td className="text-truncate">{row.id}</td>
            <td className="text-truncate">{row.phone}</td>
            <td className="text-truncate">{row.payment}</td>
            <td className="text-truncate">{row.notification}</td>
            <td className="text-truncate">{row.debt && row.debt}</td>
          </tr>
        );
      });
    }
    return null;
  }, [handleRowClick]);

  const exportToExcel = useCallback(() => {
    onExcelExport(searchU.url, page, location.pathname);
  }, [searchU, page, location.pathname, onExcelExport]);

  const previousClick = () => {
    if (props.previous) {
      setSearch({
        ...searchU,
        page: true
      });
      onGetInspectorData(props.previous, props.page - 1, location.pathname);
    }
  }

  const nextClick = () => {
    if (props.next) {
      setSearch({
        ...searchU,
        page: true
      });
      onGetInspectorData(props.next, props.page + 1, location.pathname);
    }
  }


  const getUrl = useCallback(() => {
    let url = (location.pathname.includes('non-population')) ? INSPECTOR_NON_POPULATION : INSPECTOR_POPULATION;

    if (props.id) {
      url += `&id=${props.id}`;
    }
    if (props.fullName) {
      url += `&full_name=${props.fullName}`;
    }
    if (props.phone) {
      url += `&phone=${props.phone}`;
    }
    if (props.payment) {
      url += `&payment=${props.payment}`;
    }
    if (props.notification) {
      url += `&notification=${props.notification}`;
    }
    // if(props.debt){
    //     url += `&debt=${props.debt}`;
    // }
    if (props.start) {
      url += `&start=${props.start}`;
    }
    if (props.end) {
      url += `&end=${props.end}`;
    }
    return url;
  }, [props.id, props.fullName, props.phone, /*props.debt,*/ location.pathname, props.notification, props.payment, props.start, props.end]);


  const paginationButtonClick = useCallback((p) => {
    let url = getUrl();
    url += `&page=${p}`;
    setSearch({
      ...searchU,
      page: true
    });
    onGetInspectorData(url, p, location.pathname);
  }, [onGetInspectorData, getUrl, searchU, location.pathname]);


  const getPaginations = useCallback(() => {
    if (!props.pages) {
      return null;
    }
    const showPages = 5;
    let start = 1;
    let finish = showPages;
    const data = [];
    if (props.page > 2 && props.pages > showPages) {
      start = props.page - 1;
      finish = (props.page + (showPages - 2) < props.pages) ? props.page + (showPages - 2) : props.page;
    } else if (props.pages < showPages) {
      finish = props.pages;
    }
    for (let i = 0; i <= finish - start; i++) {
      data.push(
        (
          <PaginationItem key={i} className={(i + start === props.page) ? "active" : ""}>
            <PaginationLink
              href=""
              onClick={() => paginationButtonClick(i + start)}
            >
              {i + start}
            </PaginationLink>
          </PaginationItem>
        )
      );
    }

    return data;
  }, [props.pages, props.page, paginationButtonClick]);

  const search = useCallback((event, column, key) => {
    let url = (location.pathname.includes('non-population')) ? INSPECTOR_NON_POPULATION : INSPECTOR_POPULATION + `&page=${1}`;
    if (props.id && column !== 'id') {
      url += `&id=${props.id}`;
    }
    if (props.fullName && column !== 'fullName') {
      url += `&full_name=${props.fullName}`;
    }
    if (props.phone && column !== 'phone') {
      url += `&phone=${props.phone}`;
    }
    if (props.payment) {
      url += `&payment=${props.payment}`;
    }
    if (props.payment) {
      url += `&notification=${props.notification}`;
    }
    // if(props.debt && column !== 'debt'){
    //     url += `&debt=${props.debt}`;
    // }
    if (props.start) {
      url += `&start=${props.start}`;
    }
    if (props.end) {
      url += `&end=${props.end}`;
    }
    url = (event.target.value) ? url + `&${key}=${event.target.value}` : url;
    setSearch({
      url: url,
      column: column,
      value: event.target.value,
      page: false
    });
  }, [props.id, props.fullName, props.phone, /*props.debt,*/ location.pathname, props.notification, props.payment, props.start, props.end]);



  const startChange = useCallback((value) => {
    if (value instanceof moment) {
      onStartChange(value.format('YYYY-MM-DD'));
    }

  }, [onStartChange]);

  const endChange = useCallback((value) => {
    if (value instanceof moment) {
      onEndChange(value.format('YYYY-MM-DD'));
    }
  }, [onEndChange]);


  const searchBtnClick = useCallback((value) => {
    const url = getUrl();
    onGetInspectorData(url, 1, location.pathname)
  }, [getUrl, onGetInspectorData, location.pathname]);

  // document.title = (props.companyName) ? strToUpper(props.companyName) + " | " + strToUpper(pageHeader) : "ERP";

  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          {pageHeader}
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">

          <Row>
            <Col>
              <h3 className="mb-0 custom-card-header">{pageHeader}</h3>
            </Col>
            <Col>
              <Button className="btn float-right"
                color="success"
                onClick={(e) => exportToExcel()}
              >
                Excel <i className="ml-1 fas fa-file-download"></i>
              </Button>
            </Col>
          </Row>

        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <Row>
            <Col md="3">

            </Col>
            <Col md="7" >
              <DatePicker {...props} onStartChange={startChange} onEndChange={endChange} />
            </Col>
            <Col md="2">
              <Button className="btn w-100"
                color="info"
                onClick={(e) => searchBtnClick()}
              >
                Axtar
              </Button>
            </Col>
          </Row>

          <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{(props.count) ? props.count : 0} Nəticə</span>
          <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">AD, SOYAD</th>
                <th scope="col">ID</th>
                <th scope="col">ƏLAQƏ NÖMRƏSİ</th>
                <th scope="col">ÖDƏNİŞ</th>
                <th scope="col">BİLDİRİŞ</th>
                <th scope="col">BORC</th>
              </tr>
              <tr>
                <th scope="col">
                  <Input placeholder="Ad, Soyad" type="text" onChange={(e) => search(e, "fullName", 'full_name')} className="form-control-sm" />
                </th>
                <th scope="col">
                  <Input placeholder="ID" type="text" onChange={(e) => search(e, "id", 'id')} className="form-control-sm" />
                </th>
                <th scope="col">
                  <Input placeholder="Əlaqə nömrəsi" type="text" className="form-control-sm" onChange={(e) => search(e, "phone", 'phone')} />
                </th>
                <th scope="col">
                  <Input placeholder="Ödəniş" type="text" className="form-control-sm" /*onChange={(e) => search(e, "payment", 'payment')}*/ disabled />
                </th>
                <th scope="col">
                  <Input placeholder="Bildiriş" type="text" className="form-control-sm" /*onChange={(e) => search(e, "debt", 'debt')} */ disabled />
                </th>
                <th scope="col">
                  <Input placeholder="Borc" type="text" className="form-control-sm" /*onChange={(e) => search(e, "debt", 'debt')} */ disabled />
                </th>
              </tr>
            </thead>
            <tbody>
              {getRows(props.results)}
            </tbody>
          </Table>
        </CardBody>
        {(props.results) ?
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={(!props.previous) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={previousClick}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {getPaginations()}
                <PaginationItem className={(!props.next) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={nextClick}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter> : null}
      </Card>
    </Container>
  );
}



const mapStateToProps = state => {
  return {
    count: state.inspector.count,
    next: state.inspector.next,
    pages: state.inspector.pages,
    previous: state.inspector.previous,
    results: state.inspector.results,
    page: state.inspector.page,
    id: state.inspector.id,
    fullName: state.inspector.fullName,
    phone: state.inspector.phone,
    payment: state.inspector.payment,
    notification: state.inspector.notification,
    // debt: state.inspector.debt,
    start: state.inspector.start,
    end: state.inspector.end,
    companyName: state.company.companyName,
  };
};



const mapDispatchToProps = dispatch => {
  return {
    onGetInspectorData: (url = null, page = 1, pathname) => dispatch(inspectorData(url, page, pathname)),
    onChangeInputData: (column = null, search = null) => dispatch(changeInputData(column, search)),
    onExcelExport: (url = null, page = 1, pathname) => dispatch(inspectorDataExcel(url, page, pathname)),
    onSetInitial: () => dispatch(setInitial()),
    onStartChange: (value) => dispatch(setDateProps('start', value)),
    onEndChange: (value) => dispatch(setDateProps('end', value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inspector);
