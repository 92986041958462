import React, { Fragment } from "react";

import BaseNavbar from "components/Navbars/BaseNavbar";
import Footer from "components/Footers/DefaultFooter";

import { Route, Switch, Redirect } from "react-router-dom";

import Document from 'views/department/Document';
import AddDocument from 'views/department/AddDocument';
import EditDocument from "views/department/EditDocument";
import { strToUpper, getDepartmentName } from 'helpers/utils';
import { useSelector } from 'react-redux';


const Law = props => {

  const companyName = useSelector(state => state.company.companyName);

  document.title = (companyName) ? strToUpper(companyName +  ` | ${getDepartmentName('law')}`)  : "ERP";
  return (
    <Fragment>
      <BaseNavbar departmentName={getDepartmentName('law')} />
      <div className="min-window-height-100">
        <Switch>
          <Route exact path='/law/document/add' render={(props) => <AddDocument {...props} basePath="law" />} />
          <Route exact path='/law/document/:id' render={(props) => <EditDocument {...props} basePath="law" />} />
          <Route exact path='/law/document' render={(props) => <Document {...props} basePath="law" />} />
          <Redirect from='*' to='/law/document' />
        </Switch>
      </div>

      <Footer className="bg-white custom-footer-2"/>
    </Fragment>
  )
}


export default Law;
