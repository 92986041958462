import React from 'react';

import BarData from 'components/charts/BarData';
import PieData from 'components/charts/PieData';
// import Meter from 'components/index/Meter';

import { useParams } from "react-router-dom";

import {
  Row,
  Col,
} from 'reactstrap';

const Statistic = props => {
  const { id } = useParams();

  return (
    <Row>
      <Col xl="6">
        <BarData header="Hesabat" inspector={id} />
      </Col>

      <Col xl="6">
        <PieData inspector={id} />
      </Col>
    </Row>
  );
}


export default Statistic;
