import React, { useEffect, useCallback } from "react";
import { getServices } from 'store/actions/company';
import { store } from 'react-notifications-component';
import { notificationOptions } from 'helpers/utils';
import { PAYER_DETAIL } from "api/urls";
import { connect } from 'react-redux';
import { decimalInTwoStep } from "shared/utility";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input
} from "reactstrap";


const EditModal = props => {

  const {
    modal, modalToggle, services, onGetServices, payerId, onGetPayerData, onPayerDebt, onBtnDisabledEvent, btnDisabled, location, values, setValues
  } = props;

  useEffect(() => {
    if (!services) {
      onGetServices();
    }
  }, [services, onGetServices]);

  // const [values, setValues] = useState(debtData);

  const onInputChange = useCallback((input, value, values) => {
    const updatedValues = { ...values };
    updatedValues[input] = value;
    setValues(updatedValues);
  }, [setValues]);


  const getOptions = useCallback((selectedService) => {

    if (!services) return null;

    const type = (location.pathname.includes('non-population')) ? 'non-population' : 'population';

    return services.map((service, i) => {

      if (service.type !== type) return null;

      if (selectedService && selectedService === service.id) {
        return <option value={service.id} defaultValue key={i}>{service.name}</option>
      }

      return <option value={service.id} key={i}>{service.name}</option>
    });

  }, [services, location.pathname]);


  const saveData = useCallback(async (data) => {
    const URL = PAYER_DETAIL + `${payerId}/debts/${data.id}/`;
    onBtnDisabledEvent(true);
    const response = await fetch(URL, {
      method: "PUT",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        service: data.service,
        main_amount: data.main_amount,
        final_amount: data.final_amount
      })
    });

    if ([200, 201].includes(response.status)) {
      store.addNotification({
        ...notificationOptions,
        title: "Uğurlu əməliyyat!",
        message: 'Borc məlumatı müvəffəqiyyətlə silindi.',
        type: 'success',
      });
      onBtnDisabledEvent(false);
      modalToggle();
      onGetPayerData(payerId);
      onPayerDebt(payerId);
    } else {
      store.addNotification({
        ...notificationOptions,
        title: "Uğursuz əməliyyat!",
        message: 'Xəta baş verdi..',
        type: 'danger',
      });
      onBtnDisabledEvent(false);
    }
  }, [payerId, onGetPayerData, onPayerDebt, onBtnDisabledEvent, modalToggle]);

  return (
    <Modal
      isOpen={modal}
      toggle={modalToggle}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={modalToggle}></ModalHeader>
      <ModalBody>
        <Card>
          <CardBody className="px-lg-5 py-lg-5">
            <FormGroup>
              <Label>Vergi növü *</Label>
              <Input
                placeholder="Vergi növü"
                type="select"
                className="form-control"
                onChange={(e) => onInputChange('service', parseInt(e.target.value), values)}
                value={(values.service) ? values.service : ''}
                required
              >
                {getOptions(values.service)}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Xalis borc *</Label>
              <Input
                placeholder="Xalis borc"
                type="number"
                className="form-control"
                onChange={(e) => onInputChange('main_amount', decimalInTwoStep(e.target.value), values)}
                value={(values.main_amount) ? values.main_amount : ''}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Faiz borcu *</Label>
              <Input
                placeholder="Faiz borcu"
                type="number"
                className="form-control"
                onChange={(e) => onInputChange('final_amount', decimalInTwoStep(e.target.value), values)}
                value={(values.final_amount) ? values.final_amount : ''}
                required
              />
            </FormGroup>
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button color="info" onClick={() => saveData(values)} disabled={btnDisabled}>Yadda saxla</Button>{' '}
        <Button className="ml-auto" color="link" onClick={modalToggle}>Ləğv et</Button>
      </ModalFooter>
    </Modal>
  );
}



const mapStateToProps = state => {
  return {
    services: state.company.services,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetServices: () => dispatch(getServices()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditModal);
