export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

export const FETCH_PAYERPOPULATION_SUCCESS = 'FETCH_PAYERPOPULATION_SUCCESS';
export const FETCH_PAYERPOPULATION_FAIL = 'FETCH_PAYERPOPULATION_FAIL';
export const FETCH_PAYERPOPULATION_INPUT_DATA = 'FETCH_PAYERPOPULATION_INPUT_DATA';

export const FETCH_PAYERNONPOPULATION_SUCCESS = 'FETCH_PAYERNONPOPULATION_SUCCESS';
export const FETCH_PAYERNONPOPULATION_FAIL = 'FETCH_PAYERNONPOPULATION_FAIL';
export const FETCH_PAYERNONPOPULATION_INPUT_DATA = 'FETCH_PAYERNONPOPULATION_INPUT_DATA';


export const FETCH_PAYER_SUCCESS = 'FETCH_PAYER_SUCCESS';
export const FETCH_PAYER_FAIL = 'FETCH_PAYER_FAIL';
export const FETCH_PAYER_INPUT_DATA = 'FETCH_PAYER_INPUT_DATA';

export const FETCH_DEBT_SUCCESS = 'FETCH_DEBT_SUCCESS';
export const FETCH_DEBT_FAIL = 'FETCH_DEBT_FAIL';


export const EXCEL_SUCCESS = 'EXCEL_SUCCESS';

export const FETCH_INSPECTOR_SUCCESS = 'FETCH_INSPECTOR_SUCCESS';
export const FETCH_INSPECTOR_FAIL = 'FETCH_INSPECTOR_FAIL';
export const FETCH_INSPECTOR_INPUT_DATA = 'FETCH_INSPECTOR_INPUT_DATA';

export const SET_INITIAL = 'SET_INITIAL';

export const SET_INITIAL_SOCKET = 'SET_INITIAL_SOCKET';

export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_FAIL = 'FETCH_DOCUMENT_FAIL';
