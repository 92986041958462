import { updateObject } from '../../shared/utility';
import { SET_INITIAL, FETCH_INSPECTOR_INPUT_DATA } from '../actions/actionTypes';

const initialState = {
  count: null,
  next: null,
  pages: null,
  previous: null,
  results: null,
  page: 1,
  id: null,
  fullName: null,
  phone: null,
  payment: null,
  notification: null,
  // debt: null,
  start: null,
  end: null,
  error: null,
};

const reducer = (state = initialState, action) => {

  if (action.type === SET_INITIAL) {
    return updateObject(state, initialState);
  }

  if (action.type === FETCH_INSPECTOR_INPUT_DATA) {
    if (action.data.hasOwnProperty('start')) {
      if (Date.parse(action.data.start) > new Date()) {
        return state;
      } else if (state.end && state.end < action.data.start) {
        return state;
      }

    } else if (action.data.hasOwnProperty('end')) {
      if (Date.parse(action.data.end) > new Date()) {
        return state;
      }
      else if (state.start && state.start > action.data.end) {
        return state;
      }
    }

  }

  if (action.data) {
    return updateObject(state, action.data);
  }
  return state;
};

export default reducer;
