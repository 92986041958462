import { LOGIN, LOGOUT } from "../../api/urls";
import * as actionTypes from './actionTypes';



export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: data.token,
    userId: data.id,
    email: data.email,
  };
};

export const authFail = (error) => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('email');
  return {
    type: actionTypes.AUTH_FAIL,
    error: true
  };
};

export const logout = () => {

  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  localStorage.removeItem('email');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};
export const clearError = () => {
  return dispatch => dispatch({
    type: 'REMOVE_ERROR',
    data: {
      error: false,
    }
  });
}

export const onLogout = () => {
  return dispatch => {
    fetch(LOGOUT, {
      method: "POST",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    }).then(resp => {
      dispatch(logout());
    }).catch(err => dispatch(authFail(err)));
  }
}

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const auth = (authData) => {
  return dispatch => {
    dispatch(authStart());
    fetch(LOGIN, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(authData)
    }).then(resp => {
        if (!resp.ok) throw Error("An Error occured")
        return resp.json()
      })
      .then(data => {

        if (data.hasOwnProperty('message')) {
          dispatch(authFail(data.message[0]));
        } else {
          console.log(data);
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.user.id);
          localStorage.setItem('email', data.user.email);
          dispatch(authSuccess({
            token: data.token,
            id: data.user.id,
            email: data.user.email
          }));
        }
      })
      .catch(err => {
        console.log(err);
        dispatch(authFail());
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const userId = localStorage.getItem('userId');
      const email = localStorage.getItem('email');
      dispatch(authSuccess({
        token: token,
        id: userId,
        email: email,
        loading: false,
      }));
    }
  };
};
