import React, { useEffect } from "react";

import Odometer from 'react-odometerjs';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardDataTotal } from 'store/actions/index';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap';

const Meter = () => {

  const dispatch = useDispatch();

  const notificationTitle = useSelector(state => state.dashboard.notificationTitle);
  const notificationTotal = useSelector(state => state.dashboard.notificationTotal);
  const paymentTitle = useSelector(state => state.dashboard.paymentTitle);
  const paymentTotal = useSelector(state => state.dashboard.paymentTotal);
  const bankTitle = useSelector(state => state.dashboard.bankTitle);
  const bankTotal = useSelector(state => state.dashboard.bankTotal);

  useEffect(() => {
    dispatch(dashboardDataTotal());
  }, [dispatch]);


  return (
    <Row>
      <Col className="mb-5 mb-xl-0" xl="4">
        <Card className="bg-notification shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <Col>
                <h3 className="text-white ls-1 mb-1">
                  {notificationTitle}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <h1 className="w-100 text-center counter-text-style">
              <Odometer value={notificationTotal} format="(.ddd),dd" theme='plaza' /> <span className="text-white ml-2">₼</span>
            </h1>
          </CardBody>
        </Card>
      </Col>
      <Col className="mb-5 mb-xl-0" xl="4">
        <Card className="bg-payment shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <Col>
                <h3 className="text-white ls-1 mb-1">
                  {paymentTitle}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <h1 className="w-100 text-center counter-text-style">
              <Odometer value={paymentTotal} format="(.ddd),dd" theme='plaza' /> <span className="text-white ml-2">₼</span>
            </h1>
          </CardBody>
        </Card>
      </Col>
      <Col className="mb-5 mb-xl-0" xl="4">
        <Card className="bg-bank shadow">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <Col>
                <h3 className="text-white ls-1 mb-1">
                  {bankTitle}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <h1 className="w-100 text-center counter-text-style">
              <Odometer value={bankTotal} format="(.ddd),dd" theme='plaza' /> <span className="text-white ml-2">₼</span>
            </h1>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}


export default Meter;
