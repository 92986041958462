import React, { useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, Switch } from "react-router-dom";
import companyData, { userGroups } from 'store/actions/company';
// import Finance from "containers/Finance";
import Admin from 'containers/Admin';
import Logout from "views/Logout";
import FinanceRouters from 'containers/Finance';
import Law from "containers/Law";
import Organizational from "containers/Organizational";
import General from "containers/General";

const Main = () => {
  const dispatch = useDispatch();
  const groups = useSelector(state => state.company.groups);

  useEffect(() => {
    dispatch(companyData());
    dispatch(userGroups());
  }, [dispatch]);

  let financeRoute, lawRoute,
  lawRedirect, generalRoute, generalRedirect,
  organizationalRoute, organizationalRedirect, baseRoute, baseRedirect;

  if(groups && groups.length > 0){
    groups.forEach(group => {
      switch (group.name){
        case "Finance Department" || "Managers":
          if (groups.length === 1) {
            lawRedirect = <Redirect from='/' to="/finance" />;
          }
          financeRoute = <Route path="/finance" render={(props) => <FinanceRouters {...props} />} />;
          break;

        case "Law Department":
          if (groups.length === 1) {
            lawRedirect = <Redirect from='/' to="/law" />;
          }
          lawRoute = <Route path="/law" render={(props) => <Law {...props} />} />;
          break;

        case "General Department":
          if (groups.length === 1) {
            generalRedirect = <Redirect from='/' to="/general" />;
          }

          generalRoute = <Route path="/general" render={(props) => <General {...props} />} />;
          break;

        case "Organizational Department":
          if (groups.length === 1) {
            organizationalRedirect = <Redirect from='/' to="/organizational" />;
          }

          organizationalRoute = <Route path="/organizational" render={(props) => <Organizational {...props} />} />;
          break;

        default:
          return null;
      }
    });

    if(groups.length > 1){
      baseRoute = <Route path="/" render={(props) => <Admin {...props} />} />;
      baseRedirect = <Redirect to="/" />;
    }
  }


  return (

    <Switch>
      <Route path="/auth/logout" render={(props) => <Logout {...props} />} />
      {financeRoute}
      {lawRoute}
      {lawRedirect}
      {generalRoute}
      {generalRedirect}
      {organizationalRoute}
      {organizationalRedirect}
      {baseRoute}
      {baseRedirect}
    </Switch>
  );
}

export default Main;
