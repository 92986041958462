import { updateObject } from '../../shared/utility';
import { SET_INITIAL } from '../actions/actionTypes';

const initialState = {
  count: null,
  next: null,
  pages: null,
  previous: null,
  results: null,
  page: 1,
  payerId: null,
  member_id: '29011',
  full_name: null,
  address: null,
  privilege: null,
  privilegeChange: false,
  flat: null,
  residential_area: null,
  land_area: null,
  lived_people_count: null,
  region: null,
  house: null,
  main_amount: null,
  final_amount: null,
  total_amount: null,
  notificationStatus: undefined,
  // showError: undefined,
  total: null,
  error: null,
  btnDisabled: false,
};

const reducer = (state = initialState, action) => {
  if (action.type === SET_INITIAL) {
    return updateObject(state, initialState);
  }

  if (action.data) {
    return updateObject(state, action.data);
  }
  return state;
};

export default reducer;
