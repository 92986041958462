import React, { useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";



const Header = React.memo(props => {

  const {
    onGetInspectorDetail,
  } = props;

  const { id } = useParams();

  useEffect(() => {
    onGetInspectorDetail(id);
  }, [onGetInspectorDetail, id]);


  const shortName = useCallback(() => {
    let name = '';
    if (props.fullName) {
      const fn = props.fullName.split(" ");
      for (let i = 0; i < fn.length; i++) {
        if (i < 2) {
          name += fn[i].toUpperCase().charAt(0);
        }
      }
    }
    return name;
  }, [props.fullName]);


  return (
    <h2 className="mb-0 d-flex" >
      {(props.inspectorImage) ? <span className="avatar avatar-sm rounded-circle">
        <img
          alt="..."
          src={props.inspectorImage}
        />
      </span> :
        <span className="profile-rounded-circle">
          {shortName()}
        </span>}
      <span className="profile-circle-name">{props.fullName}</span>
    </h2>
  );
});



export default Header;
