import thunk from 'redux-thunk';
import authReducer from 'store/reducers/auth';
import companyReducer from 'store/reducers/company';
import payerPopulation from 'store/reducers/payerPopulation';
import payerNonPopulation from 'store/reducers/payerNonPopulation';
import payerDetail from 'store/reducers/payerDetail';
import navbarToggle from 'store/reducers/navbarToggle';
import inspector from 'store/reducers/inspector';
import inspectorDetail from 'store/reducers/inspectorDetail';
import dashboard from 'store/reducers/dashboard';
import map from 'store/reducers/map';
import socket from 'store/reducers/socket';
import addPayer from 'store/reducers/addPayer';
import document from 'store/reducers/document';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  company: companyReducer,
  payerPopulation: payerPopulation,
  payerNonPopulation: payerNonPopulation,
  payerDetail: payerDetail,
  navbarToggle: navbarToggle,
  inspector: inspector,
  inspectorDetail: inspectorDetail,
  dashboard: dashboard,
  map: map,
  socket: socket,
  addPayer: addPayer,
  document: document,
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

export default store;
