import { COMPANY, PRIVILEGES_TYPE, SERVICES_API, GROUPS, REGIONS_API } from "../../api/urls";
import { FETCH_COMPANY_SUCCESS, FETCH_COMPANY_FAIL } from "./actionTypes";
import { filterGroups } from 'helpers/utils';


const companyData = () => {
  return dispatch => {
    fetch(COMPANY, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        let isAdmin = (data.user_type !== "normal") ? true : false;
        dispatch({
          type: FETCH_COMPANY_SUCCESS,
          data: {
            companyId: data.id,
            companyName: data.name,
            companyPhone: data.phone,
            companyLogo: data.logo,
            username: data.username,
            userFullName: data.full_name,
            userPhoto: data.user_photo,
            showLog: isAdmin,
            showMap: isAdmin,
            isAdmin: isAdmin,
            edit: (isAdmin) ? true : data.permissions.edit,
            create: (isAdmin) ? true : data.permissions.create,
            delete: (isAdmin) ? true : data.permissions.delete,
          },
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: { error: err }
        })
      });
  }
}

export const getPrivilegesType = () => {
  return dispatch => {
    fetch(PRIVILEGES_TYPE, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => dispatch({
        type: SERVICES_API,
        data: {
          privilegesType: data
        },
      }))
      .catch(err => {
        console.log(err);
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: { error: err }
        })
      });
  }
}

export const getServices = () => {
  return dispatch => {
    fetch(SERVICES_API, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => dispatch({
        type: FETCH_COMPANY_SUCCESS,
        data: {
          services: data
        },
      }))
      .catch(err => {
        console.log(err);
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: { error: err }
        })
      });
  }
}


export const userGroups = () => {
  return dispatch => {
    fetch(GROUPS, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    }).then(resp => resp.json())
      .then(data => {
        let groups = [];
        if(data && data.length){
          groups = data.filter(group => filterGroups(group));
        }
        dispatch({
          type: FETCH_COMPANY_SUCCESS,
          data: {
            groups: groups
          },
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: { error: err }
        })
      });
  }
}

export const getRegions = () => {
  return dispatch => {
    fetch(REGIONS_API, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then( resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_COMPANY_SUCCESS,
          data: {
            regions: data
          },
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: { error: err }
        })
      });
  }
}

export default companyData;
