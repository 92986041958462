export const BASE_URL = "https://erp.sumaks.az/api/v1/";

export const LOGIN = `${BASE_URL}auth/login/`;
export const LOGOUT = `${BASE_URL}auth/logout/`;

export const COMPANY = `${BASE_URL}company/`;

export const GROUPS = `${BASE_URL}groups/`;

export const PAYERPOPULATION = `${BASE_URL}payers/population/?limit=100`;
export const PAYERNONPOPULATION = `${BASE_URL}payers/commercial/?limit=100`;

export const PAYER_DETAIL = `${BASE_URL}payers/`;

export const DEBT = `${BASE_URL}debt/`;

export const INSPECTOR_POPULATION = `${BASE_URL}inspectors/population/?limit=100`;

export const INSPECTOR_NON_POPULATION = `${BASE_URL}inspectors/commercial/?limit=100`;

export const INSPECTOR_DETAIL = `${BASE_URL}inspectors/detail/`;

export const WEB_SOCKET = 'https://erp.sumaks.az';

export const INDEX_API_MONTHLY = `${BASE_URL}statistic/monthly/`;

export const INDEX_API_TOTAL = `${BASE_URL}statistic/total/`;

export const INDEX_API_SERVICE = `${BASE_URL}statistic/service/`;

export const INDEX_API_WEEKLY = `${BASE_URL}statistic/weekly/`;

export const NAVBAR_SEARCH = `${BASE_URL}common/search`;

export const PRIVILEGES_TYPE = `${BASE_URL}privileges-type/`;

export const INSPECTORS_MAP_API = `${BASE_URL}inspectors/map/`;

export const ACTIVITY_LOG_API = `${BASE_URL}activity/`;

export const ADD_PAYER_POP_API = `${BASE_URL}payers/population/`;

export const ADD_PAYER_NON_POP_API = `${BASE_URL}payers/commercial/`;

export const SERVICES_API = `${BASE_URL}services/`;

export const REGIONS_API = `${BASE_URL}regions/`;

export const HOUSES_API = `${BASE_URL}houses/`;

export const DOCUMENT_TAGS = `${BASE_URL}document-tag/`;

export const DOCUMENT_TYPES =  `${BASE_URL}document-type/`;
