import React, { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import payerNonPopulationData, { changeInputData, payerNonPopulationDataExcel } from '../../store/actions/payerNonPopulation';
import { setInitial } from '../../store/actions/index';
import { PAYERNONPOPULATION } from "../../api/urls";
import { shortName } from 'helpers/utils';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Button,
  Input,
  Row,
  Col,
  CardBody,
  Badge,
  NavLink,
  Media
} from "reactstrap";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const PayerNonPopulation = (props) => {

  const { page, onChangeInputData, onGetPopulationData, onExcelExport, onSetInitial, isAdmin, create } = props;
  useEffect(() => {
    onSetInitial();
    onGetPopulationData(null, 1);
  }, [onGetPopulationData, onSetInitial]);


  const [searchU, setSearch] = useState({
    url: null,
    column: null,
    value: null,
    page: false,
  });


  useEffect(() => {
    if (searchU.url === null || searchU.page) {
      return null;
    }
    onChangeInputData(searchU.column, searchU.value);
    const timeout = setTimeout(() => {
      onGetPopulationData(searchU.url, page)
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchU, page, onGetPopulationData, onChangeInputData]);

  const { history } = props;

  const handleRowClick = useCallback((member_id) => {
    history.replace(`/finance/payers/non-population/${member_id}`);
  }, [history]);

  const getRows = useCallback((results = null) => {
    if (results) {
      return results.map((row, key) => {
        let debt = <Badge color="success">ÖDƏNİLİB</Badge>;
        if (row.debt && row.debt[0] > 0) {
          debt = <Badge color="danger">BORCLU</Badge>;
        }
        return (
          <tr key={key} onClick={() => { handleRowClick(row.id) }} >
            <td className="text-truncate" style={{ width: '25%' }}>
              {(row.full_name) ? <Media className="align-items-center">
                <Media className="d-lg-block w-25">
                  <span className="profile-rounded-circle profile-rounded-circle-sm">
                    {shortName(row.full_name)}
                  </span>
                </Media>
                <Media className="d-lg-block w-75">
                  <span className="mb-0">
                    {row.full_name}
                  </span>
                </Media>
              </Media> : null}
            </td>
            <td className="text-truncate" style={{ width: '30%' }}>{row.address}</td>
            <td className="text-truncate" style={{ width: '20%' }}>{row.member_id}</td>
            <td className="text-truncate" style={{ width: '7.5%' }}>{(row.debt && row.debt[0]) ? row.debt[0] : 0}</td>
            <td className="text-truncate" style={{ width: '7.5%' }}>{(row.debt && row.debt[1]) ? row.debt[1] : 0}</td>
            <td className="text-truncate" style={{ width: '10%' }}>{debt}</td>
          </tr>
        );
      });
    }
    return null;
  }, [handleRowClick]);

  const previousClick = () => {
    if (props.previous) {
      setSearch({
        ...searchU,
        page: true
      });
      onGetPopulationData(props.previous, props.page - 1);
    }
  }

  const nextClick = () => {
    if (props.next) {
      setSearch({
        ...searchU,
        page: true
      });
      onGetPopulationData(props.next, props.page + 1);
    }
  }


  const getUrl = useCallback(() => {
    let url = PAYERNONPOPULATION;

    if (props.memberId) {
      url += `&member_id=${props.memberId}`;
    }

    if (props.commercialName) {
      url += `&commercial_name=${props.commercialName}`;
    }
    if (props.fullName) {
      url += `&full_name=${props.fullName}`;
    }
    if (props.noPayerAddress) {
      url += `&address=${props.noPayerAddress}`;
    }
    return url;
  }, [props.memberId, props.commercialName, props.fullName, props.noPayerAddress]);


  const paginationButtonClick = useCallback((p) => {
    let url = getUrl();
    url += `&page=${p}`;
    setSearch({
      ...searchU,
      page: true
    });
    onGetPopulationData(url, p);
  }, [onGetPopulationData, getUrl, searchU]);

  const exportToExcel = useCallback(() => {
    onExcelExport(searchU.url, page);
  }, [searchU, page, onExcelExport]);


  const getPaginations = useCallback(() => {
    if (!props.pages) {
      return null;
    }
    const showPages = 5;
    let start = 1;
    let finish = showPages;
    const data = [];
    if (props.page > 2 && props.pages > showPages) {
      start = props.page - 1;
      finish = (props.page + (showPages - 2) < props.pages) ? props.page + (showPages - 2) : props.page;
    } else if (props.pages < showPages) {
      finish = props.pages;
    }
    for (let i = 0; i <= finish - start; i++) {
      data.push(
        (
          <PaginationItem key={i} className={(i + start === props.page) ? "active" : ""}>
            <PaginationLink
              href=""
              onClick={() => paginationButtonClick(i + start)}
            >
              {i + start}
            </PaginationLink>
          </PaginationItem>
        )
      );
    }

    return data;
  }, [props.pages, props.page, paginationButtonClick])


  const search = useCallback((event, column, key) => {
    let url = PAYERNONPOPULATION + `&page=1`;
    if (props.memberId && column !== 'memberId') {
      url += `&member_id=${props.memberId}`;
    }
    if (props.commercialName && column !== 'commercialName') {
      url += `&commercial_name=${props.commercialName}`;
    }
    if (props.fullName && column !== 'fullName') {
      url += `&full_name=${props.fullName}`;
    }
    if (props.noPayerAddress && column !== 'noPayerAddress') {
      url += `&address=${props.noPayerAddress}`;
    }

    url = (event.target.value) ? url + `&${key}=${event.target.value}` : url;
    setSearch({
      url: url,
      column: column,
      value: event.target.value,
      page: false
    });
  }, [props.memberId, props.commercialName, props.fullName, props.noPayerAddress]);

  const location = useLocation();

  const addLink = useCallback(() => {
    let link = location.pathname;
    if (link.slice(-1) === "/") {
      link = link.slice(0, -1);
    }

    return link + "/add";
  }, [location.pathname]);

  // document.title = (props.companyName) ? strToUpper(props.companyName) + " | " + strToUpper('Abonentlər Kommersiya') : "ERP";

  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" href="#" className="p-0" disabled>
          Abonentlər Kommersiya
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <Row>
            <Col>
              <h3 className="mb-0 custom-card-header">Abonentlər Kommersiya</h3>
            </Col>
            <Col>
              <div className="float-right clear-fix">
                {(isAdmin || create) ? <Link
                  className="btn btn-info"
                  to={addLink}
                >
                  <i className="mr-1 fas fa-plus"></i> Yeni Abonent
                </Link> : null}
                <Button className="btn"
                  color="success"
                  onClick={(e) => exportToExcel()}
                >
                  Excel <i className="ml-1 fas fa-file-download"></i>
                </Button>
              </div>
            </Col>
          </Row>

        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{(props.count) ? props.count : 0} Nəticə</span>
          <Table className="align-items-center table-striped table-hover custom-table-style table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{ width: '25%' }}>Ad, Soyad, Ata adı</th>
                <th scope="col" style={{ width: '30%' }}>ÜNVAN</th>
                <th scope="col" style={{ width: '20%' }}>ABONENT KODU</th>
                <th scope="col" style={{ width: '7.5%' }}>XALİS BORC</th>
                <th scope="col" style={{ width: '7.5%' }}>YEKUN BORC</th>
                <th scope="col" style={{ width: '10%' }}>BORC STATUSU</th>
              </tr>
              <tr>
                <th scope="col" style={{ width: '25%' }}>
                  <Input placeholder="Ad, Soyad, Ata adı" type="text" onChange={(e) => search(e, "fullName", 'full_name')} className="form-control-sm" />
                </th>
                <th scope="col" style={{ width: '30%' }}>
                  <Input placeholder="Ünvan" type="text" className="form-control-sm" onChange={(e) => search(e, "payerAddress", 'address')} />
                </th>
                <th scope="col" style={{ width: '20%' }}>
                  <Input placeholder="Abonent kodu" type="text" onChange={(e) => search(e, "memberId", 'member_id')} className="form-control-sm" />
                </th>
                <th scope="col" style={{ width: '7.5%' }}>
                  <Input placeholder="₼" type="text" className="form-control-sm" disabled />
                </th>
                <th scope="col" style={{ width: '7.5%' }}>
                  <Input placeholder="₼" type="text" className="form-control-sm" disabled />
                </th>
                <th scope="col" style={{ width: '10%' }}>
                  <Input placeholder="Status" type="text" className="form-control-sm" disabled />
                </th>
              </tr>
            </thead>
            <tbody>
              {getRows(props.results)}
            </tbody>
          </Table>
        </CardBody>
        {(props.results) ?
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={(!props.previous) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={previousClick}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {getPaginations()}
                <PaginationItem className={(!props.next) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={nextClick}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter> : null}
      </Card>
    </Container>
  );
}



const mapStateToProps = state => {
  return {
    count: state.payerNonPopulation.count,
    next: state.payerNonPopulation.next,
    pages: state.payerNonPopulation.pages,
    previous: state.payerNonPopulation.previous,
    results: state.payerNonPopulation.results,
    page: state.payerNonPopulation.page,
    memberId: state.payerNonPopulation.memberId,
    fullName: state.payerNonPopulation.fullName,
    commercialName: state.payerNonPopulation.commercialName,
    noPayerAddress: state.payerNonPopulation.noPayerAddress,
    // debt: state.payerNonPopulation.debt,
    companyName: state.company.companyName,
    create: state.company.create,
    isAdmin: state.company.isAdmin,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetPopulationData: (url = null, page = 1) => dispatch(payerNonPopulationData(url, page)),
    onChangeInputData: (column = null, search = null) => dispatch(changeInputData(column, search)),
    onExcelExport: (url = null, page = 1) => dispatch(payerNonPopulationDataExcel(url, page)),
    onSetInitial: () => dispatch(setInitial()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayerNonPopulation);
