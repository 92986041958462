import React from "react";
import {
  //Badge,
  Card,
  CardHeader,
  Container,
  // Row,
  //   UncontrolledTooltip,
  //   Button,
  //   Input,
  //   Row,
  //   Col,
  CardBody,
  Label,
} from "reactstrap";
import { connect } from 'react-redux';
// import { strToUpper } from 'helpers/utils';


import GoogleMap from "components/GoogleMap";

//import { Marker } from '@react-google-maps/api';

//import { useJsApiLoader } from "@react-google-maps/api";


const center = {
  lat: 40.4003181,
  lng: 49.8690917
}


const InspectorMap = (props) => {

  // const {onMapData, points, map, onSetMap} = props;

  // useEffect(() => {
  //   onMapData();
  // },[onMapData]);





  // document.title = (props.companyName) ? strToUpper(props.companyName) + " | " + strToUpper('Müfəttişlərin fəaliyyəti') : "ERP";
  return (
    <Container className="custom-margin-top" fluid>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <h3 className="mb-0 custom-card-header">Müfəttişlərin fəaliyyəti</h3>
        </CardHeader>
        <hr className="my-1" />
        <CardBody>
          <div className="d-flex">
            <Label className="bg-light rounded pt-1 pb-1 pl-2 pr-2">
              <img alt='Online' width={20} src={require("assets/img/icons/common/inspector_online.png").default} />
              <span className="ml-1">Aktiv</span>
            </Label>
            <Label className="bg-light rounded pt-1 pb-1 pl-2 pr-2 ml-2">
              <img alt='Online' width={20} src={require("assets/img/icons/common/inspector.png").default} />
              <span className="ml-1">Deaktiv</span>
            </Label>
          </div>
          <GoogleMap center={center} />
        </CardBody>
      </Card>
    </Container>
  );
}


const mapStateToProps = state => {
  return {
    companyName: state.company.companyName,
  };
};


// const mapDispatchToProps = dispatch => {
//   return {
//       onMapData: () => dispatch(mapData()),
//       onSetMap: (map) => dispatch(setMap(map)),
//   };
// };


export default connect(mapStateToProps, null)(InspectorMap);


