import React, { useCallback, useState, useEffect } from 'react';

import { Pie } from "react-chartjs-2";

import {
  pieOptions,
  chartColors,
} from "variables/charts.js";

import { INDEX_API_SERVICE } from 'api/urls';

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col
} from 'reactstrap';



const PieData = props => {
  const { header, inspector } = props;

  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    let url = INDEX_API_SERVICE;
    if (inspector) url += `?inspector_id=${inspector}`;

    const response = await fetch(url, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    });

    if ([200, 201].includes(response.status)) {
      const respData = await response.json();
      setData(respData);
    } else {
      console.error("An error occured when trying to fetch chart data...");
    }
  }, [inspector, setData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getPieData = useCallback((serviceData) => {
    const pieData = {
      maintainAspectRatio: false,
      responsive: false,
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: chartColors,
          hoverBackgroundColor: chartColors
        }
      ]
    };

    for (let i = 0; i < serviceData.length; i++) {
      pieData.labels.push(serviceData[i].name);
      pieData.datasets[0].data.push((serviceData[i].value) ? serviceData[i].value : 0);
    }

    return pieData;
  }, []);

  return (
    <Card className="shadow mt-5">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <Col>
            <h6 className="text-uppercase text-muted ls-1 mb-1">
              {header || "Hesabat"}
            </h6>
            <h2 className="mb-0">Vergi növü üzrə</h2>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {/* Chart */}
        <div className="chart">
          {data && <Pie
            data={getPieData(data)}
            options={pieOptions}
          />}
        </div>
      </CardBody>
    </Card>
  );
}


export default PieData;
