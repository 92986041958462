import React, { useEffect, useState, useCallback } from "react";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { BASE_URL } from 'api/urls';

import documentData from 'store/actions/document';

import { useDispatch, useSelector } from 'react-redux';

import { useLocation, Link } from "react-router-dom";
import moment from 'moment';
import DatePicker from "components/DatePicker";

import DocumentAction from "components/Department/DocumentAction";

import {
  Card,
  CardHeader,
  Container,
  Button,
  Input,
  Row,
  Col,
  CardBody,
  NavLink,
  // Label,
  FormGroup,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Nav,
  // TabContent,
  NavItem,
  Badge
} from "reactstrap";

import { getDepartmentName } from 'helpers/utils';

import classnames from 'classnames';


const Document = props => {

  const location = useLocation();

  const { basePath } = props;

  const dispatch = useDispatch();

  const [search, setSearch] = useState(false);

  const [title, setTitle] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState(null);
  const [page, setPage] = useState(1);
  const [url, setUrl] = useState(null);
  const [documentCount, setDocumentCount] = useState({});

  const results = useSelector(state => state.document.results);
  const next = useSelector(state => state.document.next);
  const previous = useSelector(state => state.document.previous);
  const count = useSelector(state => state.document.count);
  const pages = useSelector(state => state.document.pages);


  let general = basePath === "general";


  const getUrl = useCallback((page, title, startDate, endDate, status) => {
    let uRl = `${BASE_URL}${basePath}/document/?`;
    const data = [];
    if (title) {
      data.push(`title=${title}`);
    }
    if (startDate) {
      data.push(`start_date=${startDate}`);
    }
    if (endDate) {
      data.push(`end_date=${endDate}`);
    }
    if (status) {
      data.push(`status=${status}`);
    }
    if (page) {
      data.push(`page=${page}`);
    }

    uRl += data.join('&');

    return uRl;
  }, [basePath]);

  const resetState = useCallback(() => {
    setSearch(null);
    setStartDate(null);
    setEndDate(null);
    // setStatus(null);
    setPage(1);
    setUrl(null);
    setTitle(null);
  }, [setSearch, setStartDate, setEndDate, setPage, setUrl, setTitle]);

  useEffect(() => {
    if (search) {
      setSearch(false);
      dispatch(documentData(url));
    }
  }, [search, url, dispatch]);

  useEffect(() => {
    setPage(1);
    let url = `${BASE_URL}${basePath}/document/`;
    if (status) url += `?status=${status}`;
    dispatch(documentData(url));
  }, [dispatch, basePath, status]);

  useEffect(() => {
    if(Object.keys(documentCount).length === 0){
      let url = `${BASE_URL}${basePath}/document-count/`;
      const fetchCount = async () => {
        const response = await fetch(url, {
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          }
        });

        if(!response.ok) {
          console.error('An error occured whwn fetching documents count. Status code: ' + response.status);
          return
        }

        const data = await response.json();
        setDocumentCount(data);
      };

      fetchCount();
    }
  }, [basePath, documentCount]);

  const startChange = useCallback((value) => {
    if (value instanceof moment) {
      setStartDate(value.format('YYYY-MM-DD'));
    }

  }, [setStartDate]);

  const endChange = useCallback((value) => {
    if (value instanceof moment) {
      setEndDate(value.format('YYYY-MM-DD'));
    }
  }, [setEndDate]);

  const getStatusHtml = useCallback(status => {
    switch (status) {
      case 1:
        return <Badge color="default">Qəbulda</Badge>;
      case 2:
        return <Badge color="warning">İcrada</Badge>;
      case 3:
        return <Badge color="success">Arxivdə</Badge>;
      default:
        return null;
    }
  }, []);


  const getRows = useCallback((results = null) => {
    if (results) {

      return results.map((row, key) => {
        return general? (
          <tr key={key}>
            <td style={{ width: '16.66%' }}>{row.title}</td>
            <td style={{ width: '16.66%' }}>{getDepartmentName(row.group_name)}</td>
            <td style={{ width: '16.66%' }}>{row.sender}</td>
            <td style={{ width: '16.66%' }}>{row.date}</td>
            <td style={{ width: '16.66%' }}>{getStatusHtml(row.status)}</td>
            <td style={{ width: '16.66%' }}><DocumentAction basePath={basePath} resetState={resetState} document={row} /></td>
          </tr>
        ) : (
          <tr key={key}>
            <td style={{ width: '20%' }}>{row.title}</td>
            <td style={{ width: '20%' }}>{row.sender}</td>
            <td style={{ width: '20%' }}>{row.date}</td>
            <td style={{ width: '20%' }}>{getStatusHtml(row.status)}</td>
            <td style={{ width: '20%' }}><DocumentAction basePath={basePath} resetState={resetState} document={row} /></td>
          </tr>
        );
      });
    }
    return null;
  }, [basePath, resetState, general, getStatusHtml]);


  const getPaginations = useCallback((pages, page, title, startDate, endDate, status) => {
    if (!pages || pages.length < 2) {
      return null;
    }
    const showPages = 5;
    let start = 1;
    let finish = showPages;
    const data = [];
    if (page > 2 && pages > showPages) {
      start = page - 1;
      finish = (page + (showPages - 2) < pages) ? page + (showPages - 2) : page;
    } else if (pages < showPages) {
      finish = pages;
    }
    for (let i = 0; i <= finish - start; i++) {
      data.push(
        (
          <PaginationItem key={i} className={(i + start === page) ? "active" : ""}>
            <PaginationLink
              href=""
              onClick={() => {
                const endPoint = getUrl(i + start, title, startDate, endDate, status);
                setPage(i + start);
                setUrl(endPoint);
                setSearch(true);
              }}
            >
              {i + start}
            </PaginationLink>
          </PaginationItem>
        )
      );
    }

    return data;
  }, [setPage, setUrl, getUrl]);

  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          Sənədlər
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow">
        <CardHeader>
          <Row>
            <Col md="8">
              <h3 className="mb-0 custom-card-header mb-4">Sənədlər</h3>
            </Col>
            <Col md="4">
              <Row className="clear-fix justify-content-end">
                <Col xl="6">
                  <Link
                    className="btn btn-info mb-3 w-100"
                    to={"/" + basePath + "/document/add"}
                  >
                    <i className="mr-1 fas fa-plus"></i> Yeni Sənəd
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="w-100">
          <div className="nav-wrapper">
            <Nav className="nav-pills nav-fill flex-column flex-md-row" >
              <NavItem>
                <NavLink
                  className={classnames({ active: status === null })}
                  onClick={(e) => {
                    e.preventDefault();
                    resetState();
                    setStatus(null);
                  }}
                  href="#"
                >
                  Hamısı
                  { documentCount && <Badge color={ status !== null ? 'primary' : 'white'} className="ml-2 badge-md badge-floating">{documentCount.total}</Badge>}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: status === 1, 'text-default': status !== 1 }, 'btn-default' )}
                  onClick={(e) => {
                    e.preventDefault();
                    resetState();
                    setStatus(1);
                  }}
                  href="#"
                  color="info"
                >
                  Qəbul edilən
                  { documentCount && <Badge color={ status !== 1 ? 'default' : 'white'} className="ml-2 badge-md badge-floating">{documentCount.accepted}</Badge>}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: status === 2, 'text-warning': status !== 2}, 'btn-warning')}
                  onClick={(e) => {
                    e.preventDefault();
                    resetState();
                    setStatus(2);
                  }}
                  href="#"
                  color="warning"
                >
                  İcrada olan
                  { documentCount && <Badge color={ status !== 2 ? 'warning' : 'white'} className="ml-2 badge-md badge-floating">{documentCount.executed}</Badge>}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: status === 3, 'text-success': status !== 3 }, 'btn-success')}
                  onClick={(e) => {
                    e.preventDefault();
                    resetState();
                    setStatus(3);
                  }}
                  href="#"
                  color="success"
                >
                  Arxivdə olan
                  { documentCount && <Badge color={ status !== 3 ? 'success' : 'white'} className="ml-2 badge-md badge-floating">{documentCount.archive }</Badge>}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <section>
            <legend>Axtarış</legend>
            <Row>

              <Col xl="2" lg="4" md="3">
                <FormGroup>
                  <Input
                    placeholder="Sənədin adı"
                    className="form-control"
                    value={title ?? ''}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col xl="6" lg="8" md="5">
                <DatePicker
                  start={startDate}
                  end={endDate}
                  onStartChange={startChange}
                  onEndChange={endChange}
                />
              </Col>
              <Col xl="2" lg="2" md="2">
                <Button
                  className="w-100 mb-3"
                  color="danger"
                  onClick={(e) => {
                    e.preventDefault();
                    resetState();
                  }}
                >
                  Təmizlə
                </Button>
              </Col>
              <Col xl="2" lg="2" md="2">
                <Button className="btn w-100 mb-3"
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    const endPoint = getUrl(page, title, startDate, endDate, status);
                    setUrl(endPoint);
                    setSearch(true);
                  }}
                >
                  <i className="fas fa-search visible-md"></i>
                  <span className="hidden-md">Axtar</span>
                </Button>
              </Col>
            </Row>
          </section>
          <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{count ?? 0} Nəticə</span>

          <Table className="align-items-center table-hover table-bordered overflow-auto custom-table-style" responsive>
            <thead className="thead-light">
              { general ? <tr>
                <th scope="col" style={{ width: '16.66%' }}>Adı</th>
                <th scope="col" style={{ width: '16.66%' }}>Şöbə</th>
                <th scope="col" style={{ width: '16.66%' }}>Göndərən Qurum</th>
                <th scope="col" style={{ width: '16.66%' }}>Qəbul Tarixi</th>
                <th scope="col" style={{ width: '16.66%' }}>Status</th>
                <th scope="col" style={{ width: '16.66%' }}>Əməliyyatlar</th>
              </tr> : <tr>
                <th scope="col" style={{ width: '20%' }}>Adı</th>
                <th scope="col" style={{ width: '20%' }}>Göndərən Qurum</th>
                <th scope="col" style={{ width: '20%' }}>Qəbul Tarixi</th>
                <th scope="col" style={{ width: '20%' }}>Status</th>
                <th scope="col" style={{ width: '20%' }}>Əməliyyatlar</th>
              </tr> }
            </thead>
            <tbody>
              {getRows(results)}
            </tbody>
          </Table>
        </CardBody>
        {pages && pages > 1 &&
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={(!previous) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={(e) => {
                      const endPoint = getUrl(page - 1, title, startDate, endDate, status);
                      setPage(page - 1);
                      setUrl(endPoint);
                      setSearch(true);
                    }}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {getPaginations(pages, page, title, startDate, endDate, status)}
                <PaginationItem className={(!next) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={(e) => {
                      const endPoint = getUrl(page + 1, title, startDate, endDate, status);
                      setPage(page + 1);
                      setUrl(endPoint);
                      setSearch(true);
                    }}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter>}
      </Card>

    </Container>
  );
}



export default Document;
