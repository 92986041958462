import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  token: null,
  userId: null,
  email: null,
  error: false,
  authLoading: true,
  authRedirectPath: '/',
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, authLoading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.idToken,
    userId: action.userId,
    email: action.email,
    error: null,
    authLoading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    token: null,
    userId: null,
    email: null,
    error: action.error,
    authLoading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, { token: null, userId: null, email: null, authLoading: false });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
    default:
      return updateObject(state, action.data);
  }
};

export default reducer;
