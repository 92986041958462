
import React from "react";
// reactstrap components

// import { useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";

//import { strToUpper } from 'helpers/utils';

// core components

import BarData from 'components/charts/BarData';
import PieData from 'components/charts/PieData';
import Meter from 'components/index/Meter';

const Dashboard = () => {
  // const companyName = useSelector(state => state.company.companyName);
  // document.title = (companyName) ? strToUpper(companyName) + " | ÜMUMİ BAXIŞ" : "ERP";
  return (
    <Container className="custom-margin-top" fluid>
      <Card className="custom-card-style mb-5">
        <CardBody>
          <Meter />
          <Row>
            <Col xl="6">
              <BarData header="Hesabat" />
            </Col>

            <Col xl="6">
              <PieData />
            </Col>
          </Row>
        </CardBody>
      </Card>

    </Container>
  );
};



export default Dashboard;
