import { updateObject } from '../../shared/utility';

const initialState = {
  isOpen: true,
  searchResult: null,
  error: null,
  dropdownShow: false,
  searchValue: null,
}

const reducer = (state = initialState, action) => {
  return updateObject(state, action.data);
};

export default reducer;
