import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import * as actions from '../../store/actions/index';
import { onLogout } from '../store/actions/auth';

const Logout = props => {
  const { onLogoutDispatch } = props;
  useEffect(() => {
    onLogoutDispatch();
  }, [onLogoutDispatch]);

  return <Redirect to="/" />;
};

const mapDispatchToProps = dispatch => {
  return {
    onLogoutDispatch: () => dispatch(onLogout())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Logout);
