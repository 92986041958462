import React, { useEffect, useState, useCallback } from "react";
import { connect } from 'react-redux';
import payerPopulationData, { changeInputData, payerPopulationDataExcel } from '../../store/actions/payerPopulation';
import { setInitial } from '../../store/actions/index';
import { PAYERPOPULATION, HOUSES_API } from "../../api/urls";
// import {useHistory} from "react-router-dom";
import { shortName } from 'helpers/utils';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  //   DropdownMenu,
  //   DropdownItem,
  //   UncontrolledDropdown,
  //   DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  //   Progress,
  Table,
  Container,
  //   Row,
  //   UncontrolledTooltip,
  Button,
  Input,
  Row,
  Col,
  CardBody,
  NavLink,
} from "reactstrap";

import { useLocation } from "react-router-dom";

import { getRegions } from 'store/actions/company';


const PayerPopulation = (props) => {

  const {
    page, onChangeInputData, onGetPopulationData, onExcelExport, onSetInitial, create, isAdmin, history, regions, onGetRegions, region, house
  } = props;

  useEffect(() => {
    onSetInitial();
    onGetPopulationData(null, 1);
  }, [onGetPopulationData, onSetInitial]);

  const [houses, setHouses] = useState([]);

  const [searchU, setSearch] = useState({
    url: null,
    column: null,
    value: null,
    page: false,
  });


  useEffect(() => {
    if (searchU.url === null || searchU.page) {
      return null;
    }
    onChangeInputData(searchU.column, searchU.value);
    const timeout = setTimeout(() => {
      onGetPopulationData(searchU.url, page)
    }, 1000);
    return () => clearTimeout(timeout);
  }, [searchU, page, onGetPopulationData, onChangeInputData]);


  useEffect(() => {
    if(!regions || regions.length === 0) {
      onGetRegions()
    }
  }, [ onGetRegions, regions]);

  useEffect(() => {
    if (region) {
      const fetchHouses = async () => {
        const houseResponse = await fetch(HOUSES_API + `?region=${region}`, {
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          }
        });

        if ([200, 201].includes(houseResponse.status)) {
          const houseRespData = await houseResponse.json();
          setHouses(houseRespData);
        } else {
          console.error("An error occured when trying to fetch house data...");
        }
      }

      fetchHouses();
    }
  }, [region])

  const location = useLocation();

  const handleRowClick = useCallback((member_id) => {
    history.replace(`/finance/payers/population/${member_id}`);
  }, [history]);


  const addLink = useCallback(() => {
    let link = location.pathname;
    if (link.slice(-1) === "/") {
      link = link.slice(0, -1);
    }

    return link + "/add";
  }, [location.pathname]);


  const getRows = useCallback((results = null) => {
    if (results) {
      return results.map((row, key) => {
        let debt = <Badge color="success">ÖDƏNİLİB</Badge>;
        if (row.final_amount && row.final_amount > 0) {
          debt = <Badge color="danger">BORCLU</Badge>;
        }
        return (
          <tr key={key} onClick={() => { handleRowClick(row.id) }} >
            <td className="text-truncate" style={{ width: '25%' }}>
              {(row.full_name) ? <Media className="align-items-center">
                <Media className="d-lg-block w-25">
                  <span className="profile-rounded-circle profile-rounded-circle-sm">
                    {shortName(row.full_name)}
                  </span>
                </Media>
                <Media className="d-lg-block w-75">
                  <span className="mb-0">
                    {row.full_name}
                  </span>
                </Media>
              </Media> : null}
            </td>
            <td className="text-truncate" style={{ width: '28%' }}>{row.address}</td>
            <td className="text-truncate" style={{ width: '15%' }}>{row.member_id}</td>
            <td className="text-truncate" style={{ width: '8%' }}>{(row.main_amount) ? row.main_amount : 0}</td>
            <td className="text-truncate" style={{ width: '8%' }}>{(row.final_amount) ? row.final_amount : 0}</td>
            <td className="text-truncate" style={{ width: '8%' }}>{(row.total_amount) ? row.total_amount : 0}</td>
            <td className="text-truncate" style={{ width: '8%' }}>{debt}</td>
          </tr>
        );
      });
    }
    return null;
  }, [handleRowClick]);

  const exportToExcel = useCallback(() => {
    onExcelExport(searchU.url, page);
  }, [searchU, page, onExcelExport]);

  const previousClick = () => {
    if (props.previous) {
      setSearch({
        ...searchU,
        page: true
      });
      onGetPopulationData(props.previous, props.page - 1);
    }
  }

  const nextClick = () => {
    if (props.next) {
      setSearch({
        ...searchU,
        page: true
      });
      onGetPopulationData(props.next, props.page + 1);
    }
  }


  const getUrl = useCallback(() => {
    let url = PAYERPOPULATION;

    if (props.memberId) {
      url += `&member_id=${props.memberId}`;
    }
    if (props.fullName) {
      url += `&full_name=${props.fullName}`;
    }
    if (props.payerAddress) {
      url += `&address=${props.payerAddress}`;
    }
    return url;
  }, [props.memberId, props.fullName, props.payerAddress]);


  const paginationButtonClick = useCallback((p) => {
    let url = getUrl();
    url += `&page=${p}`;
    setSearch({
      ...searchU,
      page: true
    });
    onGetPopulationData(url, p);
  }, [onGetPopulationData, getUrl, searchU]);


  const getPaginations = useCallback(() => {
    if (!props.pages) {
      return null;
    }
    const showPages = 5;
    let start = 1;
    let finish = showPages;
    const data = [];
    if (props.page > 2 && props.pages > showPages) {
      start = props.page - 1;
      finish = (props.page + (showPages - 2) < props.pages) ? props.page + (showPages - 2) : props.page;
    } else if (props.pages < showPages) {
      finish = props.pages;
    }
    for (let i = 0; i <= finish - start; i++) {
      data.push(
        (
          <PaginationItem key={i} className={(i + start === props.page) ? "active" : ""}>
            <PaginationLink
              href=""
              onClick={() => paginationButtonClick(i + start)}
            >
              {i + start}
            </PaginationLink>
          </PaginationItem>
        )
      );
    }

    return data;
  }, [props.pages, props.page, paginationButtonClick]);

  const getOptions = useCallback((list, currentValue = null) => {
    return list.map((item, i) => {
      if (currentValue && item.id === currentValue) {
        return <option value={item.id} defaultValue key={i}>{item.name}</option>
      }

      return <option value={item.id} key={i} defaultValue={false}>{item.name}</option>
    });
  }, []);

  const search = (event, column, key) => {
    let url = PAYERPOPULATION + `&page=${1}`;
    if (props.memberId && column !== 'memberId') {
      url += `&member_id=${props.memberId}`;
    }
    if (props.fullName && column !== 'fullName') {
      url += `&full_name=${props.fullName}`;
    }
    if (props.payerAddress && column !== 'payerAddress') {
      url += `&address=${props.payerAddress}`;
    }
    if (props.mainAmount && column !== 'mainAmount') {
      url += `&main_amount=${props.mainAmount}`;
    }
    if (props.finalAmount && column !== 'finalAmount') {
      url += `&final_amount=${props.finalAmount}`;
    }
    if (props.debtStatus && column !== 'debtStatus') {
      url += `&debt_status=${props.debtStatus}`;
    }

    if (region && column !== 'region') {
      url += `&region=${region}`;
    }

    if (house && column !== 'house' && column !== 'region') {
      url += `&house=${house}`;
    }

    url = (event.target.value) ? url + `&${key}=${event.target.value}` : url;
    setSearch({
      url: url,
      column: column,
      value: event.target.value,
      page: false
    });
  };

  // document.title = (props.companyName) ? strToUpper(props.companyName) + " | " + strToUpper('Abonentlər Əhali') : "ERP";
  // console.log(house);

  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <NavLink color="inherit" href="#" className="p-0" disabled>
          Abonentlər Əhali
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <Row>
            <Col>
              <h3 className="mb-0 custom-card-header">Abonentlər Əhali</h3>
            </Col>
            <Col>
              <div className="float-right clear-fix">
                {(isAdmin || create) ? <Link
                  className="btn btn-info"
                  to={addLink}
                >
                  <i className="mr-1 fas fa-plus"></i> Yeni Abonent
                </Link> : null}
                <Button className="btn"
                  color="success"
                  onClick={(e) => exportToExcel()}
                >
                  Excel <i className="ml-1 fas fa-file-download"></i>
                </Button>
              </div>
            </Col>
          </Row>

        </CardHeader>
        <hr className="my-1" />
        <CardBody className="w-100">
          <Row>
            <Col md="1" className="mb-2">Bölgə:</Col>
            <Col md="5" className="mb-3">
              <Input type="select" name="select"
                onChange={(e) => {
                  onChangeInputData('house', null);
                  search(e, "region", 'region');
                }}
                className="custom-form-control-sm" >
                  <option value="" defaultValue>-------</option>
                  {getOptions(regions, region)}
              </Input>
            </Col>
            <Col md="1" className="mb-2">Bina:</Col>
            <Col md="5" className="mb-3">
              <Input type="select" name="select"
                onChange={(e) => search(e, "house", 'house')}
                className="custom-form-control-sm" >
                  <option value="" defaultValue>-------</option>
                  {getOptions(houses, house)}
              </Input>
            </Col>
          </Row>
          <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{(props.count) ? props.count : 0} Nəticə</span>
          <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{ width: '25%' }}>Ad, Soyad, Ata adı</th>
                <th scope="col" style={{ width: '28%' }}>ÜNVAN</th>
                <th scope="col" style={{ width: '15%' }}>YVÖK</th>
                <th scope="col" style={{ width: '8%' }}>XALİS BORC</th>
                <th scope="col" style={{ width: '8%' }}>YEKUN BORC</th>
                <th scope="col" style={{ width: '8%' }} title="Yekun borc + Bu ilin borcu">TOPLAM BORC</th>
                <th scope="col" style={{ width: '8%' }}>BORC STATUSU</th>
              </tr>
              <tr>
                <th scope="col" style={{ width: '25%' }}>
                  <Input placeholder="Ad, Soyad, Ata adı" type="text" onChange={(e) => search(e, "fullName", 'full_name')} className="form-control-sm" />
                </th>
                <th scope="col" style={{ width: '28%' }}>
                  <Input placeholder="Ünvan" type="text" className="form-control-sm" onChange={(e) => search(e, "payerAddress", 'address')} />
                </th>
                <th scope="col" style={{ width: '15%' }}>
                  <Input placeholder="YVÖK" type="text" onChange={(e) => search(e, "memberId", 'member_id')} className="form-control-sm" />
                </th>
                <th scope="col" style={{ width: '8%' }}>
                  <Input placeholder="₼" type="number" className="form-control-sm" onChange={(e) => search(e, "mainAmount", 'main_amount')} />
                </th>
                <th scope="col" style={{ width: '8%' }}>
                  <Input placeholder="₼" type="number" className="form-control-sm" onChange={(e) => search(e, "finalAmount", 'final_amount')} />
                </th>
                <th scope="col" style={{ width: '8%' }}>
                  <Input placeholder="₼" type="number" className="form-control-sm" readOnly />
                </th>
                <th scope="col" style={{ width: '8%' }}>
                  <Input
                    placeholder="Status"
                    type="select"
                    className="form-control-sm"
                    onChange={(e) => search(e, "debtStatus", 'debt_status')}
                  >
                    <option value="" defaultValue>-----</option>
                    <option value="indebted">Borclu</option>
                    <option value="paid">Ödənilib</option>
                  </Input>
                </th>
              </tr>
            </thead>
            <tbody>
              {getRows(props.results)}
            </tbody>
          </Table>
        </CardBody>
        {(props.results) ?
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={(!props.previous) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={previousClick}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {getPaginations()}
                <PaginationItem className={(!props.next) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={nextClick}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter> : null}
      </Card>
    </Container>
  );
}



const mapStateToProps = state => {
  return {
    count: state.payerPopulation.count,
    next: state.payerPopulation.next,
    pages: state.payerPopulation.pages,
    previous: state.payerPopulation.previous,
    results: state.payerPopulation.results,
    page: state.payerPopulation.page,
    memberId: state.payerPopulation.memberId,
    fullName: state.payerPopulation.fullName,
    payerAddress: state.payerPopulation.payerAddress,
    mainAmount: state.payerPopulation.mainAmount,
    finalAmount: state.payerPopulation.finalAmount,
    debtStatus: state.payerPopulation.debtStatus,
    region: state.payerPopulation.region,
    house: state.payerPopulation.house,
    companyName: state.company.companyName,
    create: state.company.create,
    isAdmin: state.company.isAdmin,
    regions: state.company.regions,
  };
};



const mapDispatchToProps = dispatch => {
  return {
    onGetPopulationData: (url = null, page = 1) => dispatch(payerPopulationData(url, page)),
    onChangeInputData: (column = null, search = null) => dispatch(changeInputData(column, search)),
    onExcelExport: (url = null, page = 1) => dispatch(payerPopulationDataExcel(url, page)),
    onGetRegions: () => dispatch(getRegions()),
    onSetInitial: () => dispatch(setInitial()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayerPopulation);
