// import { Button } from "@material-ui/core";
import React, { useCallback } from "react";

import {
  Input,
  Col,
  Label,
  FormGroup,
  Button,
  // FormFeedback,
} from "reactstrap";

import { decimalInTwoStep } from "shared/utility";



const DebtForm = props => {
  // pass phrase

  const { services, onChange } = props;

  const getOptions = useCallback(() => {
    if (!services) return null;
    return services.map((service, i) => {
      if (props.service && props.service === service.id) {
        return <option value={service.id} selected key={i}>{service.name}</option>
      }

      return <option value={service.id} key={i}>{service.name}</option>
    });

  }, [services, props.service]);

  const onInputChange = useCallback((column, value) => {

    const data = {
      service: (column !== 'service') ? props.service : decimalInTwoStep(value),
      main_amount: (column !== 'main_amount') ? props.main_amount : decimalInTwoStep(value),
      final_amount: (column !== 'final_amount') ? props.final_amount : decimalInTwoStep(value),
    }
    onChange(props.debtID, data);
  }, [props.service, props.main_amount, props.final_amount, props.debtID, onChange]);

  return (
    <>
      <Col md="4">
        <FormGroup>
          <Label>Vergi növü *</Label>
          <Input
            placeholder="Vergi növü"
            type="select"
            className="form-control form-control-sm"
            onChange={(e) => onInputChange('service', e.target.value)}
            required
          >
            <option value="" defaultValue> - - - - - - - </option>
            {getOptions()}
          </Input>
          {/* {errors[serviceLabel] && <FormFeedback>Vergi növü daxil edin!</FormFeedback>} */}
        </FormGroup>
      </Col>
      <Col md="4">
        <FormGroup>
          <Label>Xalis borc *</Label>
          <Input
            placeholder="Xalis borc"
            type="number"
            className="form-control form-control-sm"
            onChange={(e) => onInputChange('main_amount', e.target.value)}
            value={(props.main_amount) ? props.main_amount : ''}
            step='.01'
            // {...register(mainLabel, { required: true })}
            // invalid={(errors[mainLabel])? true: false}
            required
          />
          {/* {errors[mainLabel] && <FormFeedback>Xalis borc daxil edin!</FormFeedback>} */}
        </FormGroup>
      </Col>
      <Col md="3">
        <FormGroup>
          <Label>Faiz borcu *</Label>
          <Input
            placeholder="Faiz borcu"
            type="number"
            className="form-control form-control-sm"
            onChange={(e) => onInputChange('final_amount', e.target.value)}
            value={(props.final_amount) ? props.final_amount : ''}
            required
          // {...register(finalLabel, { required: true })}
          // invalid={(errors[finalLabel])? true: false}
          />
          {/* {errors[finalLabel] && <FormFeedback>Faiz borcu daxil edin!</FormFeedback>} */}
        </FormGroup>
      </Col>

      <Col md="1" className="align-self-center">
        <Button onClick={props.onDelete} className="btn btn-sm" color="danger"><i className="fa fa-window-close"></i></Button>
      </Col>

    </>
  );
}



export default DebtForm;
