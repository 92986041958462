import React, { useState, useEffect } from "react";

import { useDispatch } from 'react-redux';
import { BASE_URL } from 'api/urls';

import documentData, { changeDocumentStatus } from 'store/actions/document';

import { store } from 'react-notifications-component';

import { Link } from "react-router-dom";

import { Button } from 'reactstrap';

const notficationOptions = {
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 2000,
    onScreen: true
  }
}

const DocumentAction = props => {
  const {
    document, basePath, resetState
  } = props;


  const dispatch = useDispatch();
  const [x, setX] = useState(null);

  useEffect(() => {
    if (x) {
      let url = `${BASE_URL}${basePath}/document/${document.id}/`;
      let status = x;
      let isMounted = true;
      changeDocumentStatus(status, url)
        .then(resp => {
          if (!resp.ok) {
            throw new Error(resp.status);
          }
          store.addNotification({
            title: "Uğurlu əməliyyat!",
            message: 'Dəyişiklər müvəffəqiyyətlə yadda saxlanıldı.',
            type: 'success',
            ...notficationOptions,
          });
          url = `${BASE_URL}${basePath}/document/`;
          dispatch(documentData(url));
          if (isMounted) {
            resetState();
            setX(null);
          }
          return () => {
            isMounted = false;
          };
        })
        .catch(err => {
          console.log(err);
          store.addNotification({
            title: "Uğursuz əməliyyat!",
            message: 'Xəta baş verdi.',
            type: 'danger',
            ...notficationOptions,
          });
          if (isMounted) {
            setX(null);
          }
          return () => {
            isMounted = false;
          };
        })

    }
  }, [x, dispatch, document, basePath, resetState, setX]);


  let detailIcon;
  let leftArrow;
  let rightArrow;
  switch (document.status) {
    case 1:
      detailIcon = <Link
        className="btn btn-sm btn-info"
        to={"/" + basePath + "/document/" + document.id}
        href="#"
        title="Ətraflı"
      >
        <i className="fas fa-info-circle"></i>
      </Link>
      rightArrow = <Button color="warning" className="btn-sm" onClick={(e) => {
        e.preventDefault();
        setX(2);
      }} title="İcraya göndər"><i className="fas fa-arrow-right cursor-pointer" ></i></Button>
      break;
    case 2:
      detailIcon = <Link
        className="btn btn-sm btn-info"
        to={"/" + basePath + "/document/" + document.id}
        href="#"
        title="Düzəliş et"
      >
        <i className="fas fa-edit" ></i>
      </Link>
      rightArrow = <Button color="success" className="btn-sm" onClick={(e) => {
        e.preventDefault();
        setX(3);
      }} title="Arxivə göndər"><i className="fas fa-arrow-right cursor-pointer"></i></Button>

      leftArrow = <Button color="default" className="btn-sm" onClick={(e) => {
        e.preventDefault();
        setX(1);
      }} title="Qəbula qaytar"><i className="fas fa-arrow-left cursor-pointer"></i></Button>

      break;
    case 3:
      detailIcon = <Link
        className="btn btn-sm btn-info"
        to={"/" + basePath + "/document/" + document.id}
        href="#"
        title="Ətraflı"
      >
        <i className="fas fa-info-circle"></i>
      </Link>
      leftArrow = <Button color="warning" className="btn-sm" onClick={(e) => {
        e.preventDefault();
        setX(2);
      }} title="İcraya qaytar"><i className="fas fa-arrow-left cursor-pointer"></i></Button>
      break;
    default:
      break;
  }

  return (
    <span className="float-right">
      {detailIcon}
      {leftArrow}
      {rightArrow}
    </span>
  );
}

export default DocumentAction;
