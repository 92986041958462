import { ACTIVITY_LOG_API } from "../../api/urls";
import { FETCH_COMPANY_SUCCESS, FETCH_COMPANY_FAIL } from "./actionTypes";

const activtyData = (url = null, page = 1) => {
  return dispatch => {
    const URL = (url) ? url : ACTIVITY_LOG_API + `?page=${page}`;
    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_COMPANY_SUCCESS,
          data: { ...data, page: page },
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}


export const changePage = (page) => {
  return dispatch => {
    dispatch({
      type: FETCH_COMPANY_SUCCESS,
      data: {
        page: page,
      }
    });
  }
}


export default activtyData;
