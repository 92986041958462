import { INSPECTOR_POPULATION, INSPECTOR_NON_POPULATION } from "../../api/urls";
import { FETCH_INSPECTOR_SUCCESS, FETCH_INSPECTOR_FAIL, FETCH_INSPECTOR_INPUT_DATA } from "./actionTypes";
// import {useLocation} from "react-router-dom";

export const changeInputData = (column = null, search = null) => {
  return dispatch => {
    const obj = {
      type: FETCH_INSPECTOR_INPUT_DATA,
      data: {},
    }
    if (column) {
      obj.data[column] = search;
    }
    dispatch(obj);
  }
}

const inspectorData = (url = null, page = 1, pathname) => {

  return dispatch => {
    const URL = (url) ? url : (pathname.includes('non-population')) ? INSPECTOR_NON_POPULATION : INSPECTOR_POPULATION + `&page=${page}`;
    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_INSPECTOR_SUCCESS,
          data: { ...data, page: page },
        });
      })
      .catch(err => {
        dispatch({
          type: FETCH_INSPECTOR_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}


export const inspectorDataExcel = (url, page = 1, pathname) => {
  return dispatch => {
    const URL = (url) ?
      url + '&export=true' :
      (pathname.includes('non-population')) ?
        INSPECTOR_NON_POPULATION : INSPECTOR_POPULATION + `&page=${page}&export=true`;

    fetch(URL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        // 'Content-Type': 'application/json',
      }
    })
      .then(response => response.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `Hesabat_inspector_${new Date().toJSON().slice(0, 10)}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(err => {
        return dispatch({
          type: FETCH_INSPECTOR_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}



export const setDateProps = (type = 'start', value) => {
  return dispatch => {
    const obj = {
      type: FETCH_INSPECTOR_INPUT_DATA,
      data: {},
    }

    obj.data[type] = value;

    dispatch(obj);
  }
}

export default inspectorData;
