import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'store/actions/index';
import AuthLayout from "../containers/Auth";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Col,
  Label,
  Alert
} from "reactstrap";

const Login = () => {
  const [authData, setAuthData] = useState({
    username: '',
    password: ''
  });

  const error = useSelector(state => state.auth.error);
  const dispatch = useDispatch();

  const [ready, setReady] = useState(false);

  const inputChangedHandler = (event, name) => {

    if (error) dispatch(actions.clearError());
    const newData = {
      username: authData.username,
      password: authData.password,
    };
    if (name === "username") {
      newData.username = event.target.value;
    } else if (name === "password") {
      newData.password = event.target.value;
    }
    setAuthData(newData);
  }

  const submitHandler = event => {
    event.preventDefault();
    setReady(true);
  };

  useEffect(() => {
    if(ready){
      setReady(false);
      dispatch(actions.auth(authData));
      setAuthData({...authData, password: ''})
    }
  }, [dispatch, ready, authData])

  return (
    <AuthLayout>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-5">
              <h2>Daxil ol</h2>
            </div>
            {error && <Alert color="danger">İsdifadəçi adı və ya parol yanlışdır</Alert>}
            <Form role="form mt-2" onSubmit={submitHandler}>

              <FormGroup className="mb-3">
                <Label>İstifadəçi adı:</Label>
                <Input
                  placeholder="İstifadəçi adı"
                  type="text"
                  autoComplete="new-username"
                  autoFocus
                  onChange={(event) => inputChangedHandler(event, 'username')}
                  required
                  className={error ? 'has-danger' : ''}
                  value={authData.username}
                />
              </FormGroup>
              <FormGroup>

                <Label>Şifrə:</Label>
                <Input
                  placeholder="Şifrə"
                  type="password"
                  autoComplete="new-password"
                  onChange={(event) => inputChangedHandler(event, 'password')}
                  required
                  className={error ? 'has-danger' : ''}
                  value={authData.password}
                />
              </FormGroup>
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Daxil ol
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </AuthLayout>
  );
};

export default Login;
