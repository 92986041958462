import { updateObject } from '../../shared/utility';
import { SET_INITIAL } from '../actions/actionTypes';


const initialState = {
  count: null,
  next: null,
  pages: null,
  previous: null,
  results: null,
  page: 1,
  id: null,
  fullName: null,
  inspectorImage: null,
  inspectorPhone: null,
  activePage: 'payment',
  start: null,
  end: null,
  error: null,
};


const reducer = (state = initialState, action) => {
  if (action.type === SET_INITIAL) {
    return updateObject(state, initialState);
  }

  if (action.data) {
    return updateObject(state, action.data);
  }
  return state;
};

export default reducer;
