import { updateObject } from '../../shared/utility';
import { SET_INITIAL } from '../actions/actionTypes';

const initialState = {
  count: null,
  next: null,
  pages: null,
  previous: null,
  results: null,
  error: null,
  types: [],
  tags: [],
  fetched: false,
};

const reducer = (state = initialState, action) => {
  if (action.type === SET_INITIAL) {
    return updateObject(state, initialState);
  }

  if (action.data) {
    return updateObject(state, action.data);
  }
  return state;
}

export default reducer;
