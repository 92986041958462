import React, { useCallback, Fragment } from "react";
// react plugin used to create datetimepicker
import ReactDatetime from "react-datetime";

import moment from 'moment';


import "moment/locale/az";

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

moment.locale('az');

const DateSelector = (props) => {

  const { value, valueChange, readOnly } = props;

  // const valueDate = (value) ? moment(Date.parse(value)).locale('az') : null;

  const today = moment().locale('az');

  const disableFuture = useCallback(current => {
    return current.isBefore(today);
  }, [today]);


  return (
    <Fragment>
      <FormGroup>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
          <ReactDatetime
            inputProps={{
              placeholder: "Tarix seçin *",
              readOnly: readOnly,
              disabled: readOnly
            }}
            isValidDate={disableFuture}
            timeFormat={false}
            dateFormat='YYYY-MM-DD'
            value={value}
            readOnly={readOnly}
            renderDay={(props, currentDate, selectedDate) => {
              let classes = props.className;

              let disabled = false;

              if (currentDate.format('YYYY-MM-DD') > moment(new Date()).locale('az').format('YYYY-MM-DD')) {
                classes += " rdtDisabled";
                disabled = true;
              }
              return (
                <td {...props} className={classes} disabled={disabled}>
                  {currentDate.date()}
                </td>
              );
            }}
            onChange={valueChange}
          />
        </InputGroup>
      </FormGroup>

    </Fragment>
  );
}

export default DateSelector;
