import { SET_INITIAL, SET_INITIAL_SOCKET } from './actionTypes';
import { INDEX_API_MONTHLY, INDEX_API_TOTAL, INDEX_API_SERVICE } from '../../api/urls';
import { FETCH_COMPANY_SUCCESS, FETCH_COMPANY_FAIL } from "./actionTypes";
export {
  auth,
  logout,
  setAuthRedirectPath,
  authCheckState,
  clearError
} from './auth';


export const setInitial = () => {
  return dispatch => {
    dispatch({
      type: SET_INITIAL,
      data: null,
    });
  }
}

export const setInitialSocket = () => {
  return dispatch => {
    dispatch({
      type: SET_INITIAL_SOCKET,
      data: null,
    })
  }
}

export const dashboardDataMonthly = () => {
  return dispatch => {
    fetch(INDEX_API_MONTHLY, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => dispatch({
        type: FETCH_COMPANY_SUCCESS,
        data: {
          chartLabel: data.chartLabel,
          chartData: data.data,
        },
      }))
      .catch(err => {
        console.log(err);
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: { error: err }
        })
      });
  }
}


export const dashboardDataTotal = () => {
  return dispatch => {
    fetch(INDEX_API_TOTAL, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => dispatch({
        type: FETCH_COMPANY_SUCCESS,
        data: {
          paymentTitle: data.payment.title,
          paymentTotal: data.payment.value,
          bankTitle: data.bank.title,
          bankTotal: data.bank.value || 0,
          notificationTitle: data.notification.title,
          notificationTotal: data.notification.value,
        },
      }))
      .catch(err => {
        console.log(err);
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: { error: err }
        })
      });
  }
}


export const dashboardDataService = () => {
  return dispatch => {
    fetch(INDEX_API_SERVICE, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => dispatch({
        type: FETCH_COMPANY_SUCCESS,
        data: {
          serviceData: data,
        },
      }))
      .catch(err => {
        console.log(err);
        dispatch({
          type: FETCH_COMPANY_FAIL,
          data: { error: err }
        })
      });
  }
}

export const setPaymentTotal = (value) => {
  return dispatch => {
    dispatch({
      type: FETCH_COMPANY_SUCCESS,
      data: {
        paymentTotal: value
      }
    });
  }
}

export const setNotificationTotal = (value) => {
  return dispatch => {
    dispatch({
      type: FETCH_COMPANY_SUCCESS,
      data: {
        notificationTotal: value
      }
    });
  }
}

// export const initSocket = socket => {
//     return dispatch => {
//         dispatch({
//             type: "SUCCESS",
//             data: {
//                 socket: socket,
//             }
//         });
//     }
// }

// export const socketOnInspectors = () => {
//     return dispatch =>{
//         dispatch({
//             type: "SUCCESS",
//             data: {
//                 onInspectors: true,
//             }
//         });
//     }
// }

// export const socketOnInit = () => {
//     return dispatch => {
//         dispatch({
//             type: "SUCCESS",
//             data: {
//                 onDashboard: true,
//             }
//         });
//     }
// }
