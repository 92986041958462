import React, { useState, useEffect, useCallback } from "react";

import {
  Container,
  Card,
  CardHeader,
  CardBody,
  NavLink,
  FormGroup,
  Form,
  Row,
  Col,
  Label,
  Input,
  Button,
} from 'reactstrap';

import { useLocation, Link } from "react-router-dom";

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import DateSelector from "components/DateSelector";

import moment from 'moment';

import { saveDocument } from 'store/actions/document';

import { store } from 'react-notifications-component';

import { BASE_URL } from 'api/urls';

import { fileNames } from 'helpers/utils';

import { setTypesAndTags } from 'store/actions/document';

import { useSelector, useDispatch } from 'react-redux';

import Select from 'react-select';

import { GENERAL_DEPARTMENT, LAW_DEPARTMENT, ORGANIZATIONAL_DEPARTMENT } from 'helpers/utils';

// import makeAnimated from 'react-select/animated';

const notficationOptions = {
  insert: "top",
  container: "top-right",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 2000,
    onScreen: true
  }
}

const AddDocument = props => {
  const location = useLocation();
  const { basePath, history } = props;

  const types = useSelector(state => state.document.types);
  const tags = useSelector(state => state.document.tags);
  const fetched = useSelector(state => state.document.fetched);
  const dispatch = useDispatch();

  const [title, setTitle] = useState(null);
  const [date, setDate] = useState(null);
  const [document, setDocument] = useState(null);
  const [number, setNumber] = useState(null);
  const [sender, setSender] = useState(null);
  const [representative, setRepresentative] = useState(null);
  const [recipient, setRecipient] = useState(null);
  const [note, setNote] = useState(null);
  const [data, setData] = useState(null);
  const [btnDisable, setBtnDisable] = useState(null);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [group, setGroup] = useState(null);

  const onDateChange = useCallback((value) => {
    if (value instanceof moment) {
      setDate(value.format('YYYY-MM-DD'));
    } else {
      setDate(null);
    }

  }, [setDate]);

  useEffect(() => {
    if (data) {
      const url = `${BASE_URL}${basePath}/document/`;
      setBtnDisable(true);
      let isMounted = true;
      saveDocument({ data, url })
        .then(resp => {
          if (!resp.ok) {
            throw new Error(resp.status);
          }
          store.addNotification({
            title: "Uğurlu əməliyyat!",
            message: 'Dəyişiklər müvəffəqiyyətlə yadda saxlanıldı.',
            type: 'success',
            ...notficationOptions,
          });
          if (isMounted) {
            setData(null);
            setBtnDisable(false);
            history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1));
          }

          return () => {
            isMounted = false;
          }
        })
        .catch(err => {
          console.log(err);
          store.addNotification({
            title: "Uğursuz əməliyyat!",
            message: 'Xəta baş verdi.',
            type: 'danger',
            ...notficationOptions,
          });
          if (isMounted) {
            setData(null);
            setBtnDisable(false);
          }

          return () => {
            isMounted = false;
          }
        });

    }
  }, [data, basePath, setData, setBtnDisable, history, location]);

  useEffect(() => {
    if (!fetched) {
      dispatch(setTypesAndTags())
    }
  }, [dispatch, fetched]);

  const typeOptions = types.map(type => {
    return {
      value: type.id,
      label: type.name
    }
  });

  const tagOptions = tags.map(tag => {
    return {
      value: tag.id,
      label: tag.name
    }
  });

  const tagResult = selectedTags.map(tag => tag.value);
  const typeResult = selectedTypes.map(type => type.value);

  let general = basePath === 'general';

  if (!group && general) {
    setGroup(GENERAL_DEPARTMENT);
  }

  // history.push(location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1))

  return (
    <Container className="custom-margin-top" fluid>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className="p-2 custom-card-header">
        <Link color="inherit" to={location.pathname.substring(0, location.pathname.lastIndexOf("/") + 1)} className="p-0 nav-link">
          Sənədlər
        </Link>
        <NavLink color="inherit" href={location.pathname} className="p-0" disabled>
          Yeni Sənəd
        </NavLink>
      </Breadcrumbs>
      <Card className="shadow">
        <CardHeader className="pr-5 pl-5">
          <Row>
            <Col>
              <h2 className="mb-0 d-flex">Yeni Sənəd</h2>
            </Col>
            <Col>
              <Button
                className="btn float-right clear-fix"
                color="info"
                disabled={btnDisable}
                type="submit"
                form="form5"
              >
                <i className="mr-1 fas fa-save"></i> Yadda saxla
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="pr-5 pl-5">
          <Form id="form5" onSubmit={(e) => {
            e.preventDefault();
            let formData = {
              title, date, document,
              note, sender,
              representative, number,
              recipient,
              tags: tagResult,
              types: typeResult,
            };
            if (general) formData.group = group;
            setData(formData);
          }}>
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Sənədin adı *</Label>
                  <Input
                    placeholder="Sənədin adı"
                    type="text"
                    className="form-control"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title ?? ''}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Sənədin nömrəsi</Label>
                  <Input
                    placeholder="Sənədin nömrəsi"
                    type="text"
                    className="form-control"
                    onChange={(e) => setNumber(e.target.value)}
                    value={number ?? ''}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row >
              <Col md="6" xl="4">
                <Label>Tarix *</Label>
                <DateSelector
                  value={date}
                  valueChange={onDateChange}
                />
              </Col>
              {general &&
              <Col md="6" xl="4">
                <Label>Şöbə *</Label>
                <Input type="select"
                  onChange={(e) => setGroup(e.target.value)}
                  className="form-control" >
                  <option value={GENERAL_DEPARTMENT} defaultValue>Ümumi şöbə</option>
                  <option value={LAW_DEPARTMENT}>Hüquq şöbəsi</option>
                  <option value={ORGANIZATIONAL_DEPARTMENT}>Təşkilat şöbəsi</option>
                </Input>
              </Col>}
            </Row>
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Göndərən qurumun adı</Label>
                  <Input
                    placeholder="Göndərən qurumun adı"
                    type="text"
                    className="form-control"
                    onChange={(e) => setSender(e.target.value)}
                    value={sender ?? ''}
                  />
                </FormGroup>
              </Col>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Qurumun nümayəndəsi</Label>
                  <Input
                    placeholder="Qurumun nümayəndəsi"
                    type="text"
                    className="form-control"
                    onChange={(e) => setRepresentative(e.target.value)}
                    value={representative ?? ''}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Kim qəbul edib</Label>
                  <Input
                    placeholder="Kim qəbul edib"
                    type="text"
                    className="form-control"
                    onChange={(e) => setRecipient(e.target.value)}
                    value={recipient ?? ''}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>

              <Col md="8" xl="8">
                <FormGroup>
                  <Label>Fayl seçin *</Label>
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFileLang"
                      lang="en"
                      required
                      multiple
                      onChange={(e) => {
                        setDocument(e.target.files);
                      }}
                    />
                    <label className="custom-file-label" htmlFor="customFileLang" data-browse="Axtar">{document ? fileNames(document) : 'Fayl seçin *'}</label>
                  </div>

                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Etiket</Label>
                  <Select options={tagOptions} isMulti onChange={(values) => setSelectedTags(values)} placeholder="Seçin"/>
                </FormGroup>
              </Col>
              <Col md="6" xl="4">
                <FormGroup>
                  <Label>Sənəd növü</Label>
                  <Select options={typeOptions} isMulti onChange={(values) => setSelectedTypes(values)} placeholder="Seçin"/>
                </FormGroup>
              </Col>
              <Col md="8">
                <FormGroup>
                  <Label>Qeyd</Label>
                  <Input
                    placeholder="Qeyd"
                    type="textarea" row={4}
                    className="form-control"
                    value={note ?? ''}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}



export default AddDocument;
