import { useEffect, useState } from "react"
// import { useAuth } from "store/auth-context"
import { REGIONS_API, HOUSES_API } from 'api/urls'
// import { useDispatch, useSelector } from "react-redux"
// import { regionActions } from "store/slices/region-slice"
// import { successNotfication, errorNotification } from "helpers/notification"

export const useRegion = () => {
  // const auth = useAuth()

  // const dispatch = useDispatch()
  // const regionList = useSelector(state => state.region.regions)
  const [regionList, setRegionList] = useState([]);

  useEffect(() => {
    const fetchRegHou = async () => {
      const response = await fetch(REGIONS_API, {
        headers: {
          'Authorization': 'Token ' + localStorage.getItem('token'), //auth.token,
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        // if(response.status === 401) auth.logout()
        throw Error('An Error occured when fetching region-list')
      }
      const respData = await response.json()
      setRegionList(respData)
    }
    fetchRegHou()
  }, [])

  return regionList
}


// export const useUpdateRegion = ({id, name}) => {
//   const dispatch = useDispatch()
//   const btnDisabled = useSelector(state => state.region.btnDisabled)
//   const auth = useAuth()

//   useEffect(() => {
//     if(btnDisabled && id) {
//       const fetchUpdate = async () => {
//         const response = await fetch(REGIONS_API + `${id}/`, {
//           method: 'PUT',
//           headers: {
//             'Authorization': 'Token ' + auth.token,
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             name: name
//           })
//         })

//         if(!response.ok) {
//           if (response.status === 401) auth.logout()
//           errorNotification()
//           throw Error('status error: status=' + response.status)
//         }
//         successNotfication()
//         dispatch(regionActions.toggleBtn())
//       }

//       fetchUpdate()
//     }
//   }, [auth, dispatch, btnDisabled, id, name])

//   return
// }


// export const useSaveRegion = (save, name, setSave) => {
//   const dispatch = useDispatch()
//   const auth = useAuth()
//   useEffect(() => {
//     if (save) {
//       const saveRegion = async () => {
//         const response = await fetch(REGIONS_API, {
//           method: 'POST',
//           headers: {
//             'Authorization': 'Token ' + auth.token,
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({name})
//         });

//         if(!response.ok) {
//           if (response.status === 401) auth.logout()
//           errorNotification()
//           throw Error('status error: status=' + response.status)
//         }

//         successNotfication()
//         setSave(false)
//         dispatch(regionActions.resetState())
//       }

//       saveRegion()
//     }
//   }, [save, name, setSave, dispatch, auth])
// }


export const useHouse = (region) => {
  const [houseList, setHouseList] = useState([])
  useEffect(() => {
    const fetchHouses = async () => {

      if (region) {
        const response = await fetch(HOUSES_API + `?region=${region}`, {
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('token'), // auth.token,
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          // if(response.status === 401) auth.logout()
          throw Error('An Error occured when fetching region-list')
        }

        const data = await response.json()
        setHouseList(data)
      }
    }

    fetchHouses();
  }, [region])

  return houseList
}

// export const useSaveHouse = (save, name, id, resetState) => {
//   const auth = useAuth()
//   const dispatch = useDispatch()

//   useEffect(() => {
//     if(save) {
//       const fetchSaveHouse = async () => {
//         const response = await fetch(HOUSES_API, {
//           method: 'POST',
//           headers: {
//             'Authorization': 'Token ' + auth.token,
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({
//             region: id,
//             name
//           })
//         });

//         if(!response.ok) {
//           if (response.status === 401) auth.logout()
//           errorNotification()
//           throw Error('status error: status=' + response.status)
//         }

//         successNotfication()
//         dispatch(regionActions.resetHouses())
//         resetState()
//       }
//       fetchSaveHouse()
//     }
//   }, [save, auth, dispatch, name, id, resetState])
// }


// export const useUpdateHouse = (save, houseId, region, name, resetState) => {
//   const auth = useAuth()
//   const dispatch = useDispatch()

//   useEffect(() => {
//     if(save && houseId && region && name) {
//       const fetchSaveHouse = async () => {
//         const response = await fetch(HOUSES_API + `${houseId}/`, {
//           method: 'PUT',
//           headers: {
//             'Authorization': 'Token ' + auth.token,
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify({region, name})
//         });

//         if(!response.ok) {
//           if (response.status === 401) auth.logout()
//           errorNotification()
//           throw Error('status error: status=' + response.status)
//         }

//         successNotfication()
//         dispatch(regionActions.resetHouses())
//         resetState()
//       }
//       fetchSaveHouse()
//     }
//   }, [save, houseId, region, name, auth, dispatch, resetState])
// }


// export const useDeleteHouse = (remove, houseId, resetState) => {
//   const auth = useAuth()
//   const dispatch = useDispatch()
//   useEffect(() => {
//     if(remove && houseId) {
//       const fetchDeleteHouse = async () => {
//         const response = await fetch(HOUSES_API + `${houseId}/`, {
//           method: 'DELETE',
//           headers: {
//             'Authorization': 'Token ' + auth.token,
//             'Content-Type': 'application/json',
//           },
//         });

//         if(!response.ok) {
//           if (response.status === 401) auth.logout()
//           errorNotification()
//           throw Error('status error: status=' + response.status)
//         }

//         successNotfication()
//         dispatch(regionActions.resetHouses())
//         resetState()
//       }

//       fetchDeleteHouse()
//     }
//   }, [remove, houseId, auth, resetState, dispatch])
// }
