import { NAVBAR_SEARCH } from "../../api/urls";

const navbarToggle = (isOpen) => {
  return dispatch => {
    dispatch({
      type: "TOGGLE",
      data: {
        isOpen: isOpen
      }
    });
  }
}


export const inputSearchVal = (searchValue) => {
  return dispatch => {
    const data = {
      searchValue: searchValue
    }

    if (!searchValue) {
      data.dropdownShow = false;
      data.searchResult = null;
    }
    dispatch({
      type: "SUCCESS",
      data: data
    });


  }
}


export const navbarSearch = (value) => {
  return dispatch => {
    fetch(NAVBAR_SEARCH + "?search=" + value, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        let dropdownShow = (data) ? true : false;
        dispatch({
          type: "SUCCESS",
          data: {
            searchResult: data,
            dropdownShow: dropdownShow,
          },
        });
      })
      .catch(err => {
        dispatch({
          type: "ERROR",
          data: {
            error: err,
          }
        });
      });
  }
}

export const dropDownToggle = (dropdownShow) => {
  return dispatch => {
    dispatch({
      type: "SUCCESS",
      data: {
        dropdownShow: dropdownShow
      }
    })
  }
}


export default navbarToggle;
