import React, { useState } from 'react';
import { NavLink as NavLinkRRD } from "react-router-dom";
import { NavLink, NavItem, Nav } from "reactstrap";


const SubMenu = (props) => {
  const [subnav, setSubnav] = useState(true);

  const showSubnav = () => {
    setSubnav(!subnav);
  };

  const parentActive = () => {

    let className = "";

    for (let i = 0; i < props.subNav.length; i++) {
      if (props.location.pathname === props.subNav[i].layout + props.subNav[i].path) {
        className = "active"
      }
    }

    return className;
  }



  return (
    <NavItem>
      <NavLink to={"#"} tag={NavLinkRRD} onClick={props.subNav && showSubnav} activeClassName={parentActive()}>

        <i className={props.icon} />
        <span className={(!props.isOpen) ? "custom-d-none" : ""}>{props.name}</span>
        {props.isOpen && (<>
          {props.subNav && subnav
            ? <span className="ml-auto mr-2"><i className={props.iconOpened} /></span>
            : props.subNav
              ? <span className="ml-auto mr-2"><i className={props.iconClosed} /></span>
              : null}
        </>)}

      </NavLink>
      {props.isOpen && <Nav navbar className="custom-navbar-nav">
        {subnav &&
          props.subNav.map((item, index) => {
            return (
              <NavItem key={index}>
                {index === 0 && <hr className="my-1" />}
                <NavLink to={item.layout + item.path} tag={NavLinkRRD} onClick={props.onClick} activeClassName="active" className="pl-5">
                  {item.icon && <i className={item.icon} />}
                  {item.name}
                </NavLink>
                {props.subNav.length !== index + 1 && <hr className="my-1" />}
              </NavItem>
            );
          })}
      </Nav>}
      {!props.last && <hr className="my-1" />}
    </NavItem>
  );
};

export default SubMenu;
