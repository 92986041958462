import React, { useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import { setInitial } from '../../store/actions/index';
import activtyData, { changePage } from '../../store/actions/activityLog';
// import { strToUpper } from 'helpers/utils';
import { Link } from "react-router-dom";


import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  Col,
  CardBody,
} from "reactstrap";


const ActivityLog = props => {
  const {
    onSetInitial, count, next,
    pages, previous, results, page,
    onActivtyData, onChangePage
  } = props;

  useEffect(() => {
    onSetInitial();
  }, [onSetInitial]);

  useEffect(() => {
    let p = (page) ? page : 1;
    onActivtyData(null, p);
  }, [onActivtyData, page]);


  const getRows = useCallback((results = null) => {
    if (results) {
      return results.map((row, key) => {
        let action;
        switch (row.action) {
          case 1:
            action = "Yaratmaq";
            break;
          case 2:
            action = "Redaktə";
            break;
          case 3:
            action = "Silmək";
            break;
          default:
            action = "Redaktə";
            break;
        }

        let href = "/finance/";

        let type = (row.obj_type === 1) ? "population" : "non-population";

        switch (row.obj) {
          case 1:
            href += `payers/${type}/${row.obj_id}`;
            break;
          case 2:
            href += `inspectors/${type}/${row.obj_id}`;
            break;
          case 3:
            href += ``;
            break;
          default:
            href = "";
            break;
        }
        return (
          <tr key={key}>
            <td className="text-truncate">
              {row.full_name}
            </td>
            <td className="text-truncate" >
              {(row.obj_id) ?
                <Link to={href}>{row.obj_name}</Link> :
                row.obj_name}
            </td>
            <td className="text-truncate">{action}</td>
            <td className="text-truncate">{row.created_at}</td>
          </tr>
        );
      });
    }
    return null;
  }, []);

  const paginationButtonClick = useCallback((p) => {
    onChangePage(p);
  }, [onChangePage]);


  const getPaginations = useCallback(() => {
    if (!pages) {
      return null;
    }
    const showPages = 5;
    let start = 1;
    let finish = showPages;
    const data = [];
    if (page > 2 && pages > showPages) {
      start = page - 1;
      finish = (page + (showPages - 2) < pages) ? page + (showPages - 2) : pages;
      if (finish - start < showPages && finish - showPages > 0) {
        start = finish - showPages;
      }
      else if (finish - start < showPages && finish - start <= 0) start = 1;
    } else if (pages < showPages) {
      finish = pages;
    }
    for (let i = 0; i <= finish - start; i++) {
      data.push(
        (
          <PaginationItem key={i} className={(i + start === page) ? "active" : ""}>
            <PaginationLink
              href=""
              onClick={() => paginationButtonClick(i + start)}
            >
              {i + start}
            </PaginationLink>
          </PaginationItem>
        )
      );
    }

    return data;
  }, [pages, page, paginationButtonClick]);

  const previousClick = useCallback(() => {
    if (previous) {
      onChangePage(page - 1);
    }
  }, [page, previous, onChangePage])

  const nextClick = useCallback(() => {
    if (next) {
      onChangePage(page + 1);
    }
  }, [next, page, onChangePage])


  return (
    <Container className="custom-margin-top" fluid>
      <Card className="shadow mb-5">
        <CardHeader className="border-0">
          <Row>
            <Col>
              <h3 className="mb-0 custom-card-header">Əməliyyat Tarixçəsi</h3>
            </Col>
          </Row>

        </CardHeader>
        <hr className="my-1" />
        <CardBody className="w-100">
          <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{(count) ? count : 0} Nəticə</span>
          <Table className="align-items-center table-hover custom-table-style table-bordered" responsive>
            <thead className="thead-light">
              <tr>
                {/* style={{width: '25%'}} */}
                <th scope="col" >İsdifadəçi</th>
                <th scope="col" >Obyekt</th>
                <th scope="col" >Əməliyyat</th>
                <th scope="col" >Tarix</th>
              </tr>
            </thead>
            <tbody>
              {getRows(results)}
            </tbody>
          </Table>
        </CardBody>
        {(results) ?
          <CardFooter className="py-4">
            <nav aria-label="...">
              <Pagination
                className="pagination justify-content-end mb-0"
                listClassName="justify-content-end mb-0"
              >
                <PaginationItem className={(!previous) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={previousClick}
                    tabIndex="-1"
                  >
                    <i className="fas fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </PaginationLink>
                </PaginationItem>
                {getPaginations()}
                <PaginationItem className={(!next) ? "disabled" : ""}>
                  <PaginationLink
                    href=""
                    onClick={nextClick}
                  >
                    <i className="fas fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </CardFooter> : null}
      </Card>
    </Container>
  );
}



const mapStateToProps = state => {
  return {
    count: state.payerPopulation.count,
    next: state.payerPopulation.next,
    pages: state.payerPopulation.pages,
    previous: state.payerPopulation.previous,
    results: state.payerPopulation.results,
    page: state.payerPopulation.page,
  };
};



const mapDispatchToProps = dispatch => {
  return {
    onSetInitial: () => dispatch(setInitial()),
    onActivtyData: (url = null, page = 1) => dispatch(activtyData(url, page)),
    onChangePage: (page = 1) => dispatch(changePage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);
