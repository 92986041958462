
import React, { useCallback, Fragment } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import FinanceNavbar from "components/Navbars/FinanceNavbar";
import FinanceFooter from "components/Footers/FinanceFooter";
import Sidebar from "components/Sidebar/Sidebar";
import { connect, useSelector } from 'react-redux';
import { setSocket } from 'helpers/setSocket';
// For routes
import Dashboard from "views/finance/Dashboard";
import PayerPopulation from "views/finance/PayerPopulation";
import PayerNonPopulation from "views/finance/PayerNonPopulation";
import PayerDetail from "views/finance/PayerDetail";
import Inspector from "views/finance/Inspector";
import InspectorMap from "views/finance/Map";
import InspectorDetail from "views/finance/InspectorDetail";
import ActivityLog from "views/finance/ActivityLog";
import AddPayer from "views/finance/AddPayer";
import { strToUpper, getDepartmentName } from 'helpers/utils';


import routes from "routes";

import { setPaymentTotal, setNotificationTotal } from 'store/actions/index';



const Finance = React.memo((props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  const showMap = useSelector(state => state.company.showMap);
  const showLog = useSelector(state => state.company.showMap);
  const isAdmin = useSelector(state => state.company.isAdmin);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getBrandText = useCallback((path, routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        path.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  }, []);

  setSocket(props);

  document.title = (props.companyName) ? strToUpper(props.companyName +  ` | ${getDepartmentName('finance')}`)  : "ERP";

  return (
    <Fragment>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/",
          imgSrc: (props.companyLogo) ? props.companyLogo : "",
          imgAlt: "...",
        }}
        companyName={props.companyName}
      />
      <div className={(props.isOpen) ? "main-content" : "main-content custom-main-content"} ref={mainContent} >
        <FinanceNavbar
          {...props}
          brandText={getBrandText(props.location.pathname, routes)}
          logo={{
            innerLink: "/",
            imgSrc: (props.companyLogo) ? props.companyLogo : "",
            imgAlt: "...",
          }}
          companyName={props.companyName}
          departmentName={getDepartmentName('finance')}
        />
        <Switch>
          <Route exact path="/finance/dashboard" render={(props) => <Dashboard {...props} />} />

          <Route exact path="/finance/payers/population" render={(props) => <PayerPopulation {...props} />} />
          <Route exact path='/finance/payers/population/add' render={(props) => <AddPayer {...props} />} />
          <Route path='/finance/payers/population/:id' render={(props) => <PayerDetail {...props} />} />

          <Route exact path="/finance/payers/non-population" render={(props) => <PayerNonPopulation {...props} />} />
          <Route exact path='/finance/payers/non-population/add' render={(props) => <AddPayer {...props} />} />
          <Route path='/finance/payers/non-population/:id' render={(props) => <PayerDetail {...props} />} />

          <Route exact path="/finance/inspectors/population" render={(props) => <Inspector {...props} />} />
          <Route path='/finance/inspectors/population/:id' render={(props) => <InspectorDetail {...props} />} />

          <Route exact path="/finance/inspectors/non-population" render={(props) => <Inspector {...props} />} />
          <Route path='/finance/inspectors/non-population/:id' render={(props) => <InspectorDetail {...props} />} />

          {(isAdmin || showMap) && <Route path="/finance/map" render={(props) => <InspectorMap {...props} />} />}
          {(isAdmin || showLog) && <Route path="/finance/history" render={(props) => <ActivityLog {...props} />} />}
          <Redirect from='*' to="/finance/dashboard" />
        </Switch>
        <FinanceFooter />
      </div>
    </Fragment>
  );
});


const mapStateToProps = state => {
  return {
    companyName: state.company.companyName,
    companyPhone: state.company.companyPhone,
    companyLogo: state.company.companyLogo,
    username: state.company.username,
    userFullName: state.company.userFullName,
    error: state.company.error,
    isOpen: state.navbarToggle.isOpen,
    onDashboard: state.socket.onDashboard,
    showMap: state.company.showMap,
    showLog: state.company.showLog,
    isAdmin: state.company.isAdmin,
    companyId: state.company.companyId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSetPaymentTotal: (value) => dispatch(setPaymentTotal(value)),
    onSetNotificationTotal: (value) => dispatch(setNotificationTotal(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Finance);
