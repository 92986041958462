import React, { useCallback, useState, useEffect } from "react";
import DebtForm from './Debt';
import { connect } from 'react-redux';
import { getServices } from 'store/actions/company';
import { DEBT } from 'api/urls';
import { notificationOptions } from 'helpers/utils';
import { store } from 'react-notifications-component';
import {
  Row, Button
} from "reactstrap";


const Debts = props => {

  const { services, location, onGetServices, onPayerDebt, payerId, onGetPayerData } = props;
  const [debts, updateDebts] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  // const [saveBtn, showSaveBtn] = useState(false);

  useEffect(() => {
    if (!services) {
      onGetServices();
    }
  }, [services, onGetServices]);


  const newDebt = useCallback(() => {
    const updatedDebts = [...debts];
    updatedDebts.push({
      service: null,
      main_amount: 0,
      final_amount: 0
    });
    updateDebts(updatedDebts);
  }, [updateDebts, debts]);


  const updateDebt = useCallback((id, debt) => {
    const updatedDebts = [...debts];
    updatedDebts[id].service = debt.service;
    updatedDebts[id].main_amount = debt.main_amount;
    updatedDebts[id].final_amount = debt.final_amount;
    updateDebts(updatedDebts);
  }, [debts, updateDebts]);


  const deleteDebt = useCallback(id => {
    const updatedDebts = [...debts];
    updatedDebts.splice(id, 1);
    updateDebts(updatedDebts);
  }, [debts, updateDebts]);


  const getServicesData = useCallback(() => {
    const type = (location.pathname.includes('non-population')) ? 'non-population' : 'population';

    if (services) {
      return services.filter(service => service.type === type);
    }
    return null;
  }, [services, location.pathname]);



  const debtsHTML = debts.map((debt, i) => {
    return <DebtForm
      key={i}
      debtID={i}
      services={getServicesData()}
      {...debt}
      onChange={(id, debt) => updateDebt(id, debt)}
      onDelete={() => deleteDebt(i)}
    />
  });


  const saveDebts = useCallback(async (debts) => {
    const URL = DEBT + `${payerId}/`;
    setBtnDisabled(true);
    const response = await fetch(URL, {
      method: "POST",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(debts)
    });

    if ([200, 201].includes(response.status)) {
      store.addNotification({
        ...notificationOptions,
        title: "Uğurlu əməliyyat!",
        message: 'Dəyişikliklər müvəffəqiyyətlə yadda saxlanıldı.',
        type: 'success',
      });
      setBtnDisabled(false);
      updateDebts([]);
      onGetPayerData(payerId);
      onPayerDebt(payerId);
    } else {
      store.addNotification({
        ...notificationOptions,
        title: "Uğursuz əməliyyat!",
        message: 'Xəta baş verdi..',
        type: 'danger',
      });
      setBtnDisabled(false);
    }
  }, [payerId, setBtnDisabled, updateDebts, onPayerDebt, onGetPayerData]);


  return (
    <>
      <Row>
        {debtsHTML}
      </Row>
      <Button className="btn"
        color="info"
        onClick={newDebt}
        disabled={btnDisabled}
      >
        <i className="fa fa-plus mr-2"></i> Borc məlumatı əlavə et
      </Button>
      {(debts.length) ?
        <Button className="btn"
          color="info"
          onClick={() => saveDebts(debts)}
          disabled={btnDisabled}
        >
          Yadda saxla
        </Button> : null}
    </>
  );
}


const mapStateToProps = state => {
  return {
    services: state.company.services,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetServices: () => dispatch(getServices()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Debts);
