import { updateObject } from '../../shared/utility';
import { SET_INITIAL } from '../actions/actionTypes';

const initialState = {
  member_id: null,
  full_name: null,
  address: null,
  privilege: null,
  residential_area: null,
  land_area: null,
  notificationStatus: undefined,
  phone: null,
  // showError: undefined,
  error: null,
  debts: [],
  region: null,
  house: null,
  savePayer: false,
  btnDisabled: false,
};

const reducer = (state = initialState, action) => {
  if (action.type === SET_INITIAL) {
    return updateObject(state, initialState);
  }

  if (action.data) {
    return updateObject(state, action.data);
  }
  return state;
};

export default reducer;
