import { INSPECTORS_MAP_API } from 'api/urls';
import { FETCH_INSPECTOR_SUCCESS, FETCH_INSPECTOR_FAIL } from "./actionTypes";

const mapData = () => {
  return dispatch => {
    fetch(INSPECTORS_MAP_API, {
      method: "GET",
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then(resp => resp.json())
      .then(data => {
        dispatch({
          type: FETCH_INSPECTOR_SUCCESS,
          data: {
            points: data
          },
        });
      })
      .catch(err => {
        return dispatch({
          type: FETCH_INSPECTOR_FAIL,
          data: {
            error: err,
          }
        });
      });
  }
}

export const setMap = (map) => {
  return dispatch => {
    dispatch({
      type: FETCH_INSPECTOR_SUCCESS,
      data: {
        map: map
      },
    });
  }
}

export const updateMapData = (points) => {
  return dispatch => {
    dispatch({
      type: FETCH_INSPECTOR_SUCCESS,
      data: {
        points: points
      },
    });
  }
}

export default mapData;
