import { updateObject } from '../../shared/utility';

const initialState = {
  chartLabel: null,
  chartData: null,
  paymentTitle: null,
  paymentTotal: 0,
  bankTitle: null,
  bankTotal: 0,
  notificationTitle: null,
  notificationTotal: 0,
  serviceData: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  return updateObject(state, action.data);
};

export default reducer;
