import React, { useCallback, useEffect } from 'react';

import {
  CardBody,
  CardFooter,
  Row,
  Col,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from 'reactstrap';

import DatePicker from 'components/DatePicker';
import { INSPECTOR_DETAIL } from "api/urls";
import moment from 'moment';
import { connect } from 'react-redux';
import { setDateProps } from 'store/actions/inspector';
import { useParams } from "react-router-dom";

import { changeInputData, inspectorData, setActivePage, inspectorDataExcel, changePage } from 'store/actions/inspectorDetail';

const Bill = props => {

  const {
    onGetInspectorData, onSetActivePage, activePage, page, onExcelExport, onStartChange, onEndChange, onChangePage
  } = props;

  const { id } = useParams();


  const getUrl = useCallback(() => {
    let url = INSPECTOR_DETAIL + `${id}/${activePage}/?limit=100&page=${page}`;
    if (props.start) {
      url += `&start=${props.start}`;
    }
    if (props.end) {
      url += `&end=${props.end}`;
    }
    return url;
  }, [id, props.start, props.end, page, activePage]);



  useEffect(() => {
    onGetInspectorData(id, activePage, getUrl(), page);
  }, [onGetInspectorData, activePage, id, getUrl, page]);


  const exportToExcel = useCallback(() => {
    onExcelExport(id, activePage, getUrl(), page);
  }, [getUrl, page, onExcelExport, id, activePage]);


  const getRows = useCallback((results = null) => {
    if (results) {
      return results.map((row, key) => {
        return (
          <tr key={key}>
            <td className="text-truncate" style={{ width: '10%' }}>{row.id}</td>
            <td className="text-truncate" style={{ width: '15%' }}>{row.member_id}</td>
            <td className="text-truncate" style={{ width: '10%' }}>{row.full_name}</td>
            <td className="text-truncate" style={{ width: '20%' }}>{row.address}</td>
            <td className="text-truncate" style={{ width: '20%' }}>{row.service_name}</td>
            <td className="text-truncate" style={{ width: '10%' }}>{row.amount}</td>
            <td className="text-truncate" style={{ width: '15%' }}>{row.date}</td>
          </tr>
        );
      });
    }
    return null;
  }, []);

  const startChange = useCallback((value) => {
    if (value instanceof moment) {
      onStartChange(value.format('YYYY-MM-DD'));
    }
  }, [onStartChange]);

  const endChange = useCallback((value) => {
    if (value instanceof moment) {
      onEndChange(value.format('YYYY-MM-DD'));
    }
  }, [onEndChange]);




  const searchBtnClick = useCallback((value) => {
    const url = getUrl();
    onGetInspectorData(id, activePage, url, 1)
  }, [getUrl, onGetInspectorData, id, activePage]);

  const paginationButtonClick = useCallback((p) => {
    onChangePage(p);
  }, [onChangePage]);


  const getPaginations = useCallback(() => {
    if (!props.pages) {
      return null;
    }
    const showPages = 5;
    let start = 1;
    let finish = showPages;
    const data = [];
    if (props.page > 2 && props.pages > showPages) {
      start = props.page - 1;
      finish = (props.page + (showPages - 2) < props.pages) ? props.page + (showPages - 2) : props.pages;
      if (finish - start < showPages && finish - showPages > 0) {
        start = finish - showPages;
      }
      else if (finish - start < showPages && finish - start <= 0) start = 1;
    } else if (props.pages < showPages) {
      finish = props.pages;
    }
    for (let i = 0; i <= finish - start; i++) {
      data.push(
        (
          <PaginationItem key={i} className={(i + start === props.page) ? "active" : ""}>
            <PaginationLink
              href=""
              onClick={() => paginationButtonClick(i + start)}
            >
              {i + start}
            </PaginationLink>
          </PaginationItem>
        )
      );
    }

    return data;
  }, [props.pages, props.page, paginationButtonClick]);

  const previousClick = useCallback(() => {
    if (props.previous) {
      onChangePage(page - 1);
    }
  }, [page, props.previous, onChangePage])

  const nextClick = useCallback(() => {
    if (props.next) {
      onChangePage(page + 1);
    }
  }, [props.next, page, onChangePage])

  return (
    <>
      <CardBody className="pt-5">
        <Row>
          <Col md="1" xs="0">

          </Col>
          <Col md="7">
            <DatePicker {...props} onStartChange={startChange} onEndChange={endChange} />
          </Col>
          <Col md="2" xs="6">
            <Button className="btn w-100"
              color="info"
              onClick={(e) => searchBtnClick()}
            >
              Axtar
            </Button>
          </Col>
          <Col md="2" xs="6">
            <Button className="btn w-100"
              color="success"
              onClick={(e) => exportToExcel()}
            >
              Excel <i className="ml-1 fas fa-file-download"></i>
            </Button>
          </Col>
        </Row>

        <ul className="inspector-tabset mt-3">
          <li onClick={() => onSetActivePage('payment')} className={(activePage === 'payment') ? "inspector-tabset-tab active" : "inspector-tabset-tab"}>
            <span className="tab--link">
              ÖDƏNİŞ QƏBZLƏRİ
            </span>
          </li>
          <li onClick={() => onSetActivePage('notification')} className={(activePage === 'notification') ? "inspector-tabset-tab active" : "inspector-tabset-tab"}>
            <span className="tab--link">
              BİLDİRİŞ QƏBZLƏRİ
            </span>
          </li>
        </ul>

        <span className="mb-2 text-bold float-right pl-3 pr-3 pb-2 pt-2">{(props.count) ? props.count : 0} Nəticə</span>
        <Table className="align-items-center custom-table-style" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col" style={{ width: '10%' }}>QƏBZ №</th>
              <th scope="col" style={{ width: '15%' }}>YVÖK</th>
              <th scope="col" style={{ width: '10%' }}>AD, SOYAD, ATA ADI</th>
              <th scope="col" style={{ width: '20%' }}>ÜNVAN</th>
              <th scope="col" style={{ width: '20%' }}>VERGİ NÖVÜ</th>
              <th scope="col" style={{ width: '10%' }}>MƏBLƏĞ</th>
              <th scope="col" style={{ width: '15%' }}>TARİX</th>
            </tr>

          </thead>
          <tbody>
            {getRows(props.results)}
          </tbody>
        </Table>
      </CardBody>
      <CardFooter className="py-4">
        <nav aria-label="...">
          <Pagination
            className="pagination justify-content-end mb-0"
            listClassName="justify-content-end mb-0"
          >
            <PaginationItem className={(!props.previous) ? "disabled" : ""}>
              <PaginationLink
                href=""
                onClick={previousClick}
                tabIndex="-1"
              >
                <i className="fas fa-angle-left" />
                <span className="sr-only">Previous</span>
              </PaginationLink>
            </PaginationItem>
            {getPaginations()}
            <PaginationItem className={(!props.next) ? "disabled" : ""}>
              <PaginationLink
                href=""
                onClick={nextClick}
              >
                <i className="fas fa-angle-right" />
                <span className="sr-only">Next</span>
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </nav>
      </CardFooter>
    </>
  );
}


const mapStateToProps = state => {
  return {
    count: state.inspectorDetail.count,
    next: state.inspectorDetail.next,
    pages: state.inspectorDetail.pages,
    previous: state.inspectorDetail.previous,
    results: state.inspectorDetail.results,
    page: state.inspectorDetail.page,
    id: state.inspectorDetail.id,
    fullName: state.inspectorDetail.fullName,
    activePage: state.inspectorDetail.activePage,
    start: state.inspectorDetail.start,
    end: state.inspectorDetail.end,
    // companyName: state.company.companyName,
    // inspectorImage: state.inspectorDetail.inspectorImage,
    // inspectorPhone: state.inspectorDetail.inspectorPhone,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeInputData: (column = null, search = null) => dispatch(changeInputData(column, search)),
    onGetInspectorData: (id, type = 'payment', url = null, page = 1) => dispatch(inspectorData(id, type, url, page)),
    onSetActivePage: (type) => dispatch(setActivePage(type)),
    onExcelExport: (url = null, type = 'payment', page = 1, pathname) => dispatch(inspectorDataExcel(url, type, page, pathname)),
    onStartChange: (value) => dispatch(setDateProps('start', value)),
    onEndChange: (value) => dispatch(setDateProps('end', value)),
    onChangePage: (page) => dispatch(changePage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bill);
