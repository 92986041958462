import React from 'react';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from 'reactstrap';


const DeleteModal = props => {

  const {
    modal, modalToggle, deptId, deleteDebtRequest
  } = props;
  return (
    <Modal
      isOpen={modal}
      toggle={modalToggle}
      className="modal-dialog-centered modal-danger"
      contentClassName="bg-gradient-danger"
    >
      <ModalHeader toggle={modalToggle}></ModalHeader>
      <ModalBody>
        <div className="py-2 text-center">
          <i className="fas fa-exclamation-triangle fa-3x"></i>
          <h3 className="mt-2 text-white">Borc məlumatını silmək isdədiyinizə əminsinizmi?</h3>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-white" color="default" onClick={() => deleteDebtRequest(deptId)}>Bəli, silinsin</Button>{' '}
        <Button className="text-white ml-auto" color="link" onClick={modalToggle}>Ləğv et</Button>
      </ModalFooter>
    </Modal>
  );
}


export default DeleteModal;
