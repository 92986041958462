import React, { useCallback, useState, useEffect } from 'react';

import classnames from "classnames";

import { INDEX_API_MONTHLY, INDEX_API_WEEKLY } from 'api/urls';

import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

import Chart from "chart.js";
import { Bar } from "react-chartjs-2";

import {
  chartOptions,
  parseOptions,
  // chartColors,
} from "variables/charts.js";

const BarData = props => {

  const { header, inspector } = props;

  const [activeNav, setActiveNav] = useState(1);

  const [data, setData] = useState([]);

  const [labels, setLabels] = useState([]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const toggleNavs = useCallback(async (e = null, index) => {
    if (e) e.preventDefault();
    setActiveNav(index);
    let url = (index === 1) ? INDEX_API_MONTHLY : INDEX_API_WEEKLY;

    if (inspector) {
      url += `?inspector_id=${inspector}`;
    }

    const response = await fetch(url, {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    });

    if ([200, 201].includes(response.status)) {
      const respData = await response.json();
      setData(respData.data);
      setLabels(respData.chartLabel)
    } else {
      console.error("An error occured when trying to fetch chart data...");
    }
  }, [setData, setLabels, inspector]);

  useEffect(() => {
    toggleNavs(null, 1);
  }, [toggleNavs]);

  const getChartOptions = useCallback((labels, data) => {
    return {
      options: {
        scales: {
          yAxes: [{
            ticks: {
              callback: (value) => {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              },
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: (item, data) => {
              const label = data.datasets[item.datasetIndex].label || "";
              let content = "";
              if (data.datasets.length > 1) {
                content += label;
              }
              content += item.yLabel;
              return content;
            },
          },
        },
      },
      data: {
        labels: labels,
        datasets: [
          {
            label: "Bildiriş ",
            data: (data) ? data[0] : [],
            maxBarThickness: 10,
            backgroundColor: "rgb(0, 214, 143)"
          },
          {
            label: "Yığım ",
            data: (data) ? data[1] : [],
            maxBarThickness: 10,
            backgroundColor: "rgb(51, 102, 255)"
          },
          {
            label: "Bank ",
            data: (data) ? data[2] : [],
            maxBarThickness: 10,
            backgroundColor: "rgb(203, 59, 124)"
          },
        ],
      },
    }
  }, []);


  const options = getChartOptions(labels, data);

  return (
    <Card className="shadow mt-5">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <Col xs="5">
            <h6 className="text-uppercase text-muted ls-1 mb-1">
              {header || "Hesabat"}
            </h6>
            <h2 className="mb-0">Aylıq / Həftəlik</h2>
          </Col>
          <Col xs="7">
            <Nav className="justify-content-end" pills>
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: activeNav === 1,
                  })}
                  href=""
                  onClick={(e) => toggleNavs(e, 1)}
                >
                  <span className="d-none d-md-block">Aylar üzrə</span>
                  <span className="d-md-none">A</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames("py-2 px-3", {
                    active: activeNav === 2,
                  })}
                  data-toggle="tab"
                  href=""
                  onClick={(e) => toggleNavs(e, 2)}
                >
                  <span className="d-none d-md-block">Həftə üzrə</span>
                  <span className="d-md-none">H</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {/* Chart */}
        <div className="chart">
          {data && <Bar
            data={options.data}
            options={options.options}
          />}
        </div>
      </CardBody>
    </Card>
  );
}


export default BarData;
